﻿/// <reference path="views/common/changepassword/changepasswordEmailctrl.min.js" />
/// <reference path="../common/services/designcost/designcostresource.min.js" />
/// <reference path="../common/services/designcost/designcostresource.min.js" />
/// <reference path="../common/services/numberService.min.js" />
/// <reference path="views/template/customerinfo/prc/partnershiprevenuecreditctrl.min.js" />
/// <reference path="views/template/reports/vibrantvue/wip/vibrantvuewipctrl.min.js" />
/// <reference path="views/template/payment/paymentcarddeatils/paymentcarddetailsctrl.min.js" />
/// <reference path="../common/services/vvorder/vvorderreturnresource.min.js" />
/// <reference path="views/template/reports/automatereport/automatereportctrl.min.js" />
/// <reference path="views/template/reports/shippingscan/shippingscanctrl.min.js" />
/// <reference path="views/template/reports/production/productionctrl.min.js" />



var myApp = angular.module('myApp', ['ui.router', 'ui.grid', 'ngMaterial', 'ngMessages', 'ngAnimate',
    'oc.lazyLoad', 'common.services', 'LocalStorageModule', 'ngStorage', "ui.router.state.events",
    'toaster', '720kb.datepicker', 'ngSanitize', 'ui.grid', 'ui.grid.pagination', 'ui.grid.pinning',
    'ui.grid.selection', 'ui.grid.edit', 'ui.bootstrap',
    'ui.grid.rowEdit', 'ui.grid.resizeColumns', 'ui.grid.exporter',
    'ui.grid.selection', 'ui.grid.moveColumns', 'ui.mask', 'MassAutoComplete', 'ui.grid.grouping', 'checklist-model',
    'ng-currency', 'btorfs.multiselect', 'angular.filter']);//, 'ngSanitize' 'ngScrollbars'

var ENV = { version: '0.13022025.01' };

myApp.config(function ($stateProvider, $urlRouterProvider, $injector) {

    /*added by shwetha
 Desc:to clear cache keeping version number*/
    //var appSetting = $injector.get('appSettings');
    if (!ENV) {
        ENV.version = jsversion("");
    }
    /*end*/
    $urlRouterProvider.otherwise('/login');

    $stateProvider

        // Login Page ========================================
        .state('login', {
            url: '/login',
            cache: false,
            templateUrl: 'app/views/template/login/login.html?v=' + ENV.version,
            controller: 'LoginCtrl as lg',
            resolve: {
                version: function ($localStorage) {
                    localStorage.setItem("version", ENV.version);
                    $localStorage.version = ENV.version;
                },
                loginModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "loginModule",
                        files: ['app/views/template/login/loginCtrl.min.js?v=' + ENV.version,
                        'common/services/users/userResource.min.js?v=' + ENV.version,
                        'common/services/users/UserResourceRole.min.js?v=' + ENV.version,
                        'common/services/myAccount/forgotPasswordResource.min.js?v=' + ENV.version,]
                    })
                }]
            }
        })

        .state('header', {
            url: '/header',
            templateUrl: 'app/views/common/header.html?v=' + ENV.version,
            cache: false,
            controller: 'HeaderCtrl as hdr',
            resolve: {
                loginModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "headerModule",
                        files: ['common/services/application/applicationResource.min.js?v=' + ENV.version]
                    })
                }]
            }

        })


        // register Page ========================================

        //.state('register', {
        //    url: '/register',
        //    templateUrl: 'app/views/template/register/register.html?v=' + ENV.version,
        //    controller: 'RegisterCtrl as rc',
        //    resolve: {
        //        registerModule: ['$ocLazyLoad', function ($ocLazyLoad) {
        //            return $ocLazyLoad.load({
        //                name: "registerModule",
        //                files: ['app/views/template/register/registerCtrl.min.js?v=' + ENV.version,
        //                        'common/services/register/registerResource.min.js?v=' + ENV.version
        //                ]
        //            })
        //        }]
        //    }

        //})
        //.state('confirmEmail', {
        //    url: '/confirmEmail?userId&code',
        //    templateUrl: 'app/views/common/emailverification/emailVerification.html?v=' + ENV.version,
        //    controller: "emailVerificationCtrl as ecnf",
        //    resolve: {
        //        requestNewModule: ['$ocLazyLoad', function ($ocLazyLoad) {
        //            return $ocLazyLoad.load({
        //                name: "requestNewModule",
        //                files: ['app/views/common/emailVerification/emailVerificationCtrl.min.js?v=' + ENV.version,
        //                        'common/services/myAccount/confirmEmailResource.min.js?v=' + ENV.version
        //                ]
        //            })
        //        }]
        //    }
        //})

        .state('forgotPassword', {
            url: "/forgotPassword",
            templateUrl: 'app/views/common/forgotPassword/forgotPassword.html?v=' + ENV.version,
            cache: false,
            controller: 'ForgotPasswordCtrl as fp',
            resolve: {
                forgotPasswordModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "forgotPasswordModule",
                        files: ['app/views/common/forgotPassword/forgotPasswordCtrl.min.js?v=' + ENV.version,
                        'common/services/myAccount/forgotPasswordResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('resetUrl', {
            url: "/resetUrl?email&code",
            templateUrl: 'app/views/common/resetPassword/resetPassword.html?v=' + ENV.version,
            cache: false,
            controller: 'ResetPasswordCtrl as resetPass',
            resolve: {
                resetPasswordModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "resetPasswordModule",
                        files: ['app/views/common/resetPassword/resetPasswordCtrl.min.js?v=' + ENV.version,
                        'common/services/myAccount/resetPasswordResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })
        .state('changePassword', {
            url: "/change-password",
            templateUrl: 'app/views/common/changePassword/changePassword.html?v=' + ENV.version,
            cache: false,
            controller: 'ChangePasswordCtrl as changePass',
            resolve: {
                changePasswordModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "changePasswordModule",
                        files: ['app/views/common/changePassword/changePasswordCtrl.min.js?v=' + ENV.version,
                        'common/services/myAccount/changePasswordResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('changePassWordEmail', {
            url: "/changePassWordEmail?email&code",
            templateUrl: 'app/views/common/resetPassword/resetPassword.html?v=' + ENV.version,
            cache: false,
            controller: 'ChangePasswordEmailCtrl as resetPass',
            resolve: {
                changePasswordModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "changePasswordModule",
                        files: ['app/views/common/resetPassword/changePasswordEmailctrl.min.js?v=' + ENV.version,
                        'common/services/myAccount/resetPasswordResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // profile Page ========================================
        .state('profile', {
            url: '/profile',
            templateUrl: 'app/views/template/profileUpdate/profile.html?v=' + ENV.version,
            cache: false,
            controller: 'ProfileCtrl',
            resolve:
            {
                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "profileModule",
                        files: ['app/views/template/profileUpdate/profileCtrl.min.js?v=' + ENV.version,
                        'common/services/myAccount/profileUpdateResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // mainProfile Page ========================================
        .state('updateprofile', {
            url: '/update-profile',
            templateUrl: 'app/views/template/profileUpdate/profile.html?v=' + ENV.version,
            cache: false,
            controller: 'ProfileCtrl',
            resolve:
            {
                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "profileModule",
                        files: ['app/views/template/profileUpdate/profileCtrl.min.js?v=' + ENV.version,
                        'common/services/myAccount/profileUpdateResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //orderReturn Controller
        .state('orderReturn', {
            url: '/order-return/:orderId/?lensStatusId/?lensId/isDxorder/isReplacementDxOrder',
            templateUrl: 'app/views/template/orderReturn/orderReturn.html?v=' + ENV.version,
            cache: false,
            controller: 'OrderReturnCtrl as odr',
            resolve:
            {
                orderReturnResource: "orderReturnResource",
                orderReturnFor: function (orderReturnResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    //  return orderReturnResource.getLensOrderReturnById({ orderId: orderId }).$promise;
                    //  return orderReturnResource.getOrderForList().$promise;
                },
                orderReturnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "orderReturnModule",
                        files: ['app/views/template/orderReturn/orderReturnCtrl.min.js?v=' + ENV.version,
                        'common/services/orderReturn/orderReturnResource.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // return lens disposal log
        .state('returnedLensDisposalLog', {
            url: '/disposal-log',
            templateUrl: 'app/views/template/logs/returnedLensDisposalLog/returnedLensDisposalLog.html?v=' + ENV.version,
            cache: false,
            controller: 'returnedLensDisposalLogCtrl as rl',
            resolve:
            {
                orderReturnResource: "orderReturnResource",
                ReturnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "ReturnModule",
                        files: ['app/views/template/logs/returnedLensDisposalLog/returnedLensDisposalLogCtrl.min.js?v=' + ENV.version,
                        'common/services/orderReturn/orderReturnResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })
        .state('cancelLog', {
            url: '/cancel-log',
            templateUrl: 'app/views/template/logs/cancelLog/cancelLog.html?v=' + ENV.version,

            cache: false,
            controller: 'cancelLogCtrl as log',
            resolve:
            {
                orderReturnResource: "cancelLogResource",
                ReturnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "ReturnModule",
                        files: ['app/views/template/logs/cancelLog/cancelLog.min.js?v=' + ENV.version,
                        'common/services/cancelLog/cancelLogResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })

        // complaint log
        .state('complaintLog', {
            url: '/complaint-log',
            templateUrl: 'app/views/template/logs/complaintLog/complaintLog.html?v=' + ENV.version,
            cache: false,
            controller: 'complaintLogCtrl as cl',
            resolve:
            {
                complaintResource: "complaintResource",
                complLog: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "complLog",
                        files: ['app/views/template/logs/complaintLog/complaintLogCtrl.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })

        .state('vvCancelLog', {
            url: '/vibrantVue-cancel-log',
            templateUrl: 'app/views/template/logs/cancelLog/vvCancelLog.html?v=' + ENV.version,
            cache: false,
            controller: 'vvCancelLogCtrl as log',
            resolve:
            {
                orderReturnResource: "cancelLogResource",
                ReturnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "ReturnModule",
                        files: ['app/views/template/logs/cancelLog/vvCancelLogCtrl.min.js?v=' + ENV.version,
                        'common/services/cancelLog/cancelLogResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('complainttrendanalysis', {
            url: '/complainttrendanalysis',
            templateUrl: 'app/views/template/complaintTrendAnalysis/complaintTrendAnalysis.html?v=' + ENV.version,
            cache: false,
            controller: 'ComplaintTrendAnalysisCtrl as ctac',
            resolve:
            {
                complaintResource: "complaintResource",
                ComplaintTrendAnalysis: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "ComplaintTrendAnalysis",
                        files: ['app/views/template/complaintTrendAnalysis/complaintTrendAnalysisCtrl.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })


        // defective duplicate remake credit

        .state('defectiveDuplicate', {
            url: '/defectiveDuplicate/:orderId/:lensId',
            templateUrl: 'app/views/template/orderReturn/defectiveDuplicate/defectiveDuplicate.html?v=' + ENV.version,
            cache: false,
            controller: 'defectiveDuplicateCtrl as vm',
            resolve:
            {
                orderReturnResource: "orderReturnResource",
                ///   orderReturnFor: function (orderReturnResource, $stateParams) {
                //    var orderId = $stateParams.orderId;
                //  return orderReturnResource.getLensOrderReturnById({ orderId: orderId }).$promise;
                //  return orderReturnResource.getOrderForList().$promise;
                //  },
                Module: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "Module",
                        files: ['app/views/template/orderReturn/defectiveDuplicate/defectiveDuplicateCtrl.min.js?v=' + ENV.version,
                        'common/services/orderReturn/orderReturnResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })


        // /:isDuplicate/:isRemake/:isCustomer
        // Eyes Page ========================================
        .state('eyes', {
            cache: false,
            url: '/eyes/:orderId?jsond?eye?orderStatusId?isEdit?startOrderId',
            templateUrl: 'app/views/template/eyes/eyesPage.html?v=' + ENV.version,
            controller: 'EyesCtrl as eyeCtr',
            resolve:
            {
                eyeResource: "eyeResource",
                eyesOrder: function (eyeResource, $stateParams) {
                    var model = {};
                    model.orderLens = [];
                    var startOrder = angular.copy($stateParams.jsond != undefined ? JSON.parse($stateParams.jsond) : []);
                    model.orderId = $stateParams.orderId !== "0" ? $stateParams.orderId : 0;
                    model.orderStatusId = $stateParams.orderStatusId !== undefined ? $stateParams.orderStatusId : 0;
                    model.isEdit = $stateParams.isEdit !== undefined ? $stateParams.isEdit : false;
                    var takenLenses = startOrder?.orderLens?.filter(x => x.isTaken === true);
                    model.orderLens = takenLenses;
                    model.startOrderId = $stateParams.startOrderId;
                    return eyeResource.getRxOrderById(model).$promise;
                },
                eyeModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "eyeModule",
                        files: ['app/views/template/eyes/eyesCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                        'common/services/shippingMethod/shippingMethodResource.min.js?v=' + ENV.version,
                        'common/services/numberService.min.js?v=' + ENV.version,
                        'common/services/designCost/designCostResource.min.js?v=' + ENV.version
                        ]
                    });
                }]
            }
        })

        //OrderDetails ========================================
        .state('orderDetails', {
            url: '/orderlist/orderDetails/:orderId/?isBackTracked',
            templateUrl: 'app/views/template/orderDetails/orderDetails.html?v=' + ENV.version,
            cache: false,
            controller: 'OrderDetailsCtrl as ordDet',
            resolve:
            {

                orderDetailResource: "orderDetailResource",
                orderDetails: function (orderDetailResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    return orderDetailResource.orderById({ orderId: orderId }).$promise;
                },
                viewOrderDetailsModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "viewOrderDetailsModule",
                        files: ['app/views/template/orderDetails/orderDetailsCtrl.min.js?v=' + ENV.version,
                        'common/services/order/orderDetailResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // VV Order details
        .state('vvOrderDetails', {
            url: '/vvOrderlist/vvOrderDetails/:orderId/?isBackTracked',
            templateUrl: 'app/views/template/vvOrderDetails/vvOrderDetails.html?v=' + ENV.version,
            cache: false,
            controller: 'vvOrderDetailsCtrl as ordDet',
            resolve:
            {

                vvOrderDetailsResource: "vvOrderDetailsResource",
                orderDetails: function (vvOrderDetailsResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    return vvOrderDetailsResource.getVVOrder({ orderId: orderId }).$promise;
                },
                vvViewOrderDetailsModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "vvViewOrderDetailsModule",
                        files: ['app/views/template/vvOrderDetails/vvOrderDetailsCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrderDetails/vvOrderDetailsResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Customer Info =============================
        .state('customerInfo', {
            url: '/customer-info/:practiceId',
            templateUrl: 'app/views/template/customerInfo/customerInfo.html?v=' + ENV.version,
            cache: false,
            controller: 'CustomerInfoCtrl as cr',
            resolve:
            {
                customerResource: "customerResource",
                customer: function (customerResource, $stateParams) {
                    var practiceId = $stateParams.practiceId;
                    var viewCustomer = $stateParams.viewCustomer;
                    return customerResource.getPracticeDetail({
                        practiceId: practiceId,
                        isPracticeUsers: true
                    }).$promise;
                },
                viewProfileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "viewProfileModule",
                        files: [
                            'app/views/template/customerInfo/customerInfoCtrl.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version,
                            'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version,
                            'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version,
                            'common/services/PracticeMemberShip/practiceMemberShipResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //VV Sales Notes Report
        .state('vvsalesreport', {
            url: '/vvsalesreport/:practiceId',
            templateUrl: 'app/views/template/customerInfo/vvSalesNotes.html?v=' + ENV.version,
            cache: false,
            controller: 'VvSalesNotesCtrl as sn',
            resolve:
            {
                customerResource: "customerResource",
                vvSalesNotes: function (customerResource, $stateParams) {
                    let practiceId = $stateParams.practiceId;
                    return customerResource.getVVSalesNotes({
                        practiceId: practiceId
                    }).$promise;
                },
                viewProfileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "viewProfileModule",
                        files: [
                            'app/views/template/customerInfo/vvSalesNotesCtrl.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('vvsalesnotereport', {
            url: '/vvsalesreport',
            templateUrl: 'app/views/template/customerInfo/vvSalesNoteReport.html?v=' + ENV.version,
            cache: false,
            controller: 'VvSalesNoteReportCtrl as sn',
            resolve:
            {
                customerResource: "customerResource",
                vvSalesNotes: function (customerResource) {
                    return customerResource.getVVSalesNoteReport().$promise;
                },
                viewProfileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "viewProfileModule",
                        files: [
                            'app/views/template/customerInfo/vvSalesNoteReportCtrl.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('customerstatusreport', {
            url: '/customerstatusreport',
            templateUrl: 'app/views/template/customerInfo/customerStatusReport/customerStatusReport.html?v=' + ENV.version,
            cache: false,
            controller: 'CustomerStatusReportCtrl as csr',
            resolve:
            {
                customerResource: "customerResource",
                CustomerStatusReportModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "CustomerStatusReportModule",
                        files: [
                            'app/views/template/customerInfo/customerStatusReport/customerStatusReportCtrl.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('recallreport', {
            url: '/recallreport',
            templateUrl: 'app/views/template/customerInfo/RecallReport/recallReport.html?v=' + ENV.version,
            cache: false,
            controller: 'RecallReportCtrl as rr',
            resolve:
            {
                customerResource: "customerResource",
                RecallReportModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "RecallReportModule",
                        files: [
                            'app/views/template/customerInfo/RecallReport/recallReportCtrl.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // Contact Page ========================================
        .state('contact', {
            url: '/contact',
            templateUrl: 'app/views/template/contact/contact.html?v=' + ENV.version,
            cache: false,
            controller: 'ContactCtrl as cnt',
            resolve:
            {
                contactModules: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'contactModules',
                        files: ['app/views/template/contact/contactCtrl.min.js?v=' + ENV.version,
                        'common/services/contact/contactResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // Invoice Page ========================================
        .state('invoice', {
            url: '/order-billing/invoice/:orderId/:isInvoice',
            templateUrl: 'app/views/template/invoice/rxInvoice.html?v=' + ENV.version,
            cache: false,
            controller: 'InvoiceCtrl as inv',
            resolve:
            {
                eyeResource: "eyeResource",
                invoiceDetails: function (eyeResource, $stateParams, $location, $rootScope) {
                    var qs = $location.search();
                    if (qs != null && qs.flag == 1) {
                        $rootScope.noNav = false;
                    }
                    var orderId = $stateParams.orderId;
                    var isInvoice = $stateParams.isInvoice;
                    return eyeResource.getInvoiceOrder({ orderId: orderId }).$promise;
                },
                invoiceModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "invoiceModule",
                        files: ['app/views/template/invoice/invoiceCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // vv order invoice
        .state('vvinvoice', {

            url: '/order-billing/vvinvoice/:orderId/:isInvoice',
            templateUrl: 'app/views/template/vvOrdersInvoice/vvOrderInvoice.html?v=' + ENV.version,
            cache: false,
            controller: 'vvOrderInvoiceCtrl as vvinv',
            resolve:
            {
                vvOrderDetailsResource: "vvOrderDetailsResource",
                invoiceDetails: function (vvOrderDetailsResource, $stateParams, $location, $rootScope) {
                    var qs = $location.search();
                    if (qs != null && qs.flag == 1) {
                        $rootScope.noNav = false;
                    }
                    var orderId = $stateParams.orderId;
                    var isInvoice = $stateParams.isInvoice;
                    return vvOrderDetailsResource.getVVOrder({ orderId: orderId }).$promise;
                },
                invoiceModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "invoiceModule",
                        files: ['app/views/template/vvOrdersInvoice/vvOrderInvoiceCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrderDetails/vvOrderDetailsResource.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })

        // Invoice Page ========================================
        .state('dfsInvoice', {
            url: '/order-billing/dfsInvoice/:orderId/:isInvoice',
            templateUrl: 'app/views/template/invoice/dxInvoice.html?v=' + ENV.version,
            cache: false,
            controller: 'DfsInvoiceCtrl as dxinv',
            resolve:
            {
                eyeResource: "eyeResource",
                invoiceDfsDetails: function (eyeResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    var isInvoice = $stateParams.isInvoice;
                    return eyeResource.getInvoiceOrder({ orderId: orderId }).$promise;
                },
                invoiceModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "invoiceModule",
                        files: ['app/views/template/invoice/dxInvoiceCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        // Dfs Order Page ========================================
        .state('dfsOrder', {
            url: '/dfs-order/:orderType/:orderId?orderStatusId/?orderstate/?isRestore',
            templateUrl: 'app/views/template/dfsOrder/dfsOrder.html?v=' + ENV.version,
            cache: false,
            controller: 'DfsOrderCtrl as dfs',
            resolve:
            {
                dfsOrderResource: "dfsOrderResource",
                dfsOrder: function (dfsOrderResource, $stateParams) {
                    //var orderId = $stateParams.orderId
                    //return dfsOrderResource.getOrderById({ orderId: orderId }).$promise;
                    var model = {};
                    model.orderId = $stateParams.orderId;
                    model.OrderState = $stateParams.orderType;
                    model.orderStatusId = $stateParams.orderStatusId !== undefined ? $stateParams.orderStatusId : 0;
                    model.isRestore = $stateParams.isRestore;
                    return dfsOrderResource.getDxAxOrderById(model).$promise;
                },
                dfsModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "dfsModule",
                        files: [
                            'app/views/template/dfsOrder/dfsOrderCtrl.min.js?v=' + ENV.version,
                            'common/services/dfsOrder/dfsOrderResource.min.js?v=' + ENV.version,
                            'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                            'common/services/shippingMethod/shippingMethodResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })


        // Orders List Page ========================================
        .state('orders', {
            url: '/orders',
            templateUrl: 'app/views/template/orderList/orders.html?v=' + ENV.version,
            cache: false,
            controller: 'OrdersCtrl as ordlst',
           
            resolve:
            {
                orderListModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderListModule',
                        files: ['app/views/template/orderList/ordersCtrl.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version
                           
                        ]
                    })
                }]
            }
        })

        // Orders List Page ========================================
        .state('practiceorders', {
            url: '/orders/:practiceid',
            templateUrl: 'app/views/template/orderList/orders.html?v=' + ENV.version,
            cache: false,
            controller: 'OrdersCtrl as ordlst',
            resolve:
            {
                orderListModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderListModule',
                        files: ['app/views/template/orderList/ordersCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //.state('orderlist', {
        //    url: '/orderlistnew',
        //    templateUrl: 'app/views/template/orderList/orderListNew.html?v=' + ENV.version,
        //    cache: false,
        //    controller: 'OrderListNewCtrl as ordlst',
        //    resolve:
        //    {
        //        orderListModule: ['$ocLazyLoad', function ($ocLazyLoad) {
        //            return $ocLazyLoad.load({
        //                name: 'orderListModule',
        //                files: [
        //                    'app/views/template/orderList/orderListNewCtrl.min.js?v=' + ENV.version,
        //                    'common/services/eye/eyeResource.min.js?v=' + ENV.version
        //                ]
        //            })
        //        }]
        //    }
        //})
        // Orders Form Page ========================================
        .state('orderform', {
            url: '/orderform',
            templateUrl: 'app/views/template/orderForm/orderForm.html?v=' + ENV.version,
            cache: false,
            controller: 'OrderFormCtrl'
        })


        // Payment Receipt Page ========================================
        .state('paymentReceipt', {
            url: '/paymentReceipts',
            templateUrl: 'app/views/template/invoice/paymentReceipt.html?v=' + ENV.version,
            controller: 'PaymentReceiptCtrl as vm',
            resolve: {
                receiptmodule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "receiptmodule",
                        files: ['app/views/template/invoice/paymentReceiptCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // Payment Statement Page ========================================
        .state('invStmt', {
            url: '/invStmt',
            templateUrl: 'app/views/template/invoice/invStmt.html?v=' + ENV.version,
            controller: 'InvStmtCtrl'
        })

        // Patient History Page ========================================
        .state('patientHistory', {
            url: '/patientHistory/:orderId',
            templateUrl: 'app/views/template/patientHistory/patientHistory.html?v=' + ENV.version,
            cache: false,
            controller: 'PatientHistoryCtrl as ph',
            resolve:
            {
                patientHistoryResource: "patientHistoryResource",
                patientDetails: function (orderDetailResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    return orderDetailResource.getPatienOrderList({ orderId: orderId }).$promise;
                },
                patientModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "patientModule",
                        files: ['app/views/template/patientHistory/patientHistoryCtrl.min.js?v=' + ENV.version,
                        'common/services/patientHistory/patientHistoryResource.min.js?v=' + ENV.version,
                        'common/services/order/orderDetailResource.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        // DFS Return Form Page ========================================
        .state('dfsReturnFm', {
            url: '/dfsReturnFm',
            templateUrl: 'app/views/template/dfsReturnFM/dfsReturnFm.html?v=' + ENV.version,
            cache: false,
            controller: 'DfsReturnFmCtrl as dfsReturnFmCtrl'
        })


        // DFS Return Form Page ========================================
        .state('dfsEvalutionResult', {
            url: '/dfsEvalutionResult',
            templateUrl: 'app/views/template/dfsEvalutionResult/dfsEvalutionResult.html?v=' + ENV.version,
            controller: 'DfsEvalutionResultCtrl'
        })

        // Return Form Page ========================================
        .state('return', {
            url: '/return',
            templateUrl: 'app/views/template/return/return.html?v=' + ENV.version,
            controller: 'ReturnCtrl'
        })


        // customer 
        .state('customers', {
            url: '/customers',
            templateUrl: 'app/views/template/addCustomer/customers.html?v=' + ENV.version,
            cache: false,
            controller: 'CustomersCtrl as cr',
            resolve:
            {

                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "customerModule",
                        files: ['app/views/template/addCustomer/customersNewCtrl.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //add Customer
        .state('addCustomer', {

            url: '/customer/:practiceId',
            templateUrl: 'app/views/template/addCustomer/addCustomer.html?v=' + ENV.version,
            cache: false,
            controller: 'AddCustomerCtrl as cr',
            resolve:
            {

                customerResource: "customerResource",
                customer: function (customerResource, $stateParams) {
                    var practiceId = $stateParams.practiceId;
                    return customerResource.getCustomerById({ practiceId: practiceId }).$promise;
                },
                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "customerModule",
                        files: ['app/views/template/addCustomer/addCustomerCtrl.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                        'common/services/PracticeMemberShip/practiceMemberShipResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })




        // view customer User
        .state('childCustomers', {
            url: '/childCustomers/:practiceId/:primaryUserId',
            templateUrl: 'app/views/template/addCustomer/addChildCustomerForm.html?v=' + ENV.version,
            cache: false,
            controller: 'ChildCustomerFormCtrl as cu',
            resolve:
            {
                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "childCustomerModule",
                        files: ['app/views/template/addCustomer/addChildCustomerFormCtrl.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //view customers
        /*commented by ajay vyas - 25-04-2020*/
        //.state('view-profile', {
        //    url: '/viewProfile/:practiceId',
        //    templateUrl: 'app/views/template/addCustomer/viewProfile.html?v=' + ENV.version,
        //    cache: false,
        //    controller: 'AddCustomerCtrl as cr',
        //    resolve:
        //    {

        //        customerResource: "customerResource",
        //        customer: function (customerResource, $stateParams) {
        //            var practiceId = $stateParams.practiceId;
        //            return customerResource.getCustomerById({ practiceId: practiceId }).$promise;
        //        },
        //        viewProfileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
        //            return $ocLazyLoad.load({

        //                name: "viewProfileModule",
        //                files: ['app/views/template/addCustomer/addCustomerCtrl.min.js?v=' + ENV.version,
        //                    'common/services/customer/customerResource.min.js?v=' + ENV.version
        //                ]
        //            })
        //        }]
        //    }
        //})

        // Order type Form Page ========================================
        .state('orderType',
            {

                url: '/orderType',
                templateUrl: 'app/masters/orderType/orderType.html?v=' + ENV.version,
                cache: false,
                controller: 'OrderTypeCtrl as ot',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/orderType/orderTypeCtrl.min.js?v=' + ENV.version,
                            'common/services/orderType/orderTypeResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })



        //Design Cost Form Page ========================================
        .state('designCost',
            {

                url: '/designCost',
                templateUrl: 'app/masters/designCost/designCost.html?v=' + ENV.version,
                cache: false,
                controller: 'DesignCostCtrl as dc',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/designCost/designCostCtrl.min.js?v=' + ENV.version,
                            'common/services/designCost/designCostResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        .state('addCost',
            {

                url: '/addCost',
                templateUrl: 'app/masters/addCost/addCost.html?v=' + ENV.version,
                cache: false,
                controller: 'AddCostCtrl as ac',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/addCost/AddCostCtrl.min.js?v=' + ENV.version,
                            'common/services/addCost/addCostResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //DISCOUNT LEVEL
        .state('discountLevel',
            {

                url: '/discountLevel',
                templateUrl: 'app/masters/discountLevel/discountLevel.html?v=' + ENV.version,
                cache: false,
                controller: 'DiscountLevelCtrl as dscntlvl',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/discountLevel/discountLevelCtrl.min.js?v=' + ENV.version,
                            'common/services/discountLevel/discountLevelResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //WARRANTY CODE
        .state('warrantyCode',
            {
                url: '/warrantyCode',
                templateUrl: 'app/masters/warrantyCode/warrantyCode.html?v=' + ENV.version,
                cache: false,
                controller: 'WarrantyCodeCtrl as wrntycd',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: 'profileModule',
                            files: ['app/masters/warrantyCode/warrantyCodeCtrl.min.js?v=' + ENV.version,
                            'common/services/warrantyCode/warrantyCodeResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //SHIPPING RATE
        .state('shippingRate',
            {

                url: '/shippingRate',
                templateUrl: 'app/masters/shippingRate/shippingRate.html?v=' + ENV.version,
                cache: false,
                controller: 'ShippingRateCtrl as shpngrate',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: 'profileModule',
                            files: ['app/masters/shippingRate/shippingRateCtrl.min.js?v=' + ENV.version,
                            'common/services/shippingRate/shippingRateResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        // Form Page ========================================
        .state('form', {
            url: '/form',
            templateUrl: 'app/views/template/form/form.html?v=' + ENV.version,
            cache: false,
            controller: 'FormCtrl as frmCtrl'
        })

        //itemGroup Path Declarartion
        .state('itemGroup', {
            url: '/itemGroup',
            templateUrl: 'app/masters/itemGroup/itemGroup.html?v=' + ENV.version,
            cache: false,
            controller: 'ItemGroupCtrl as igrp',
            resolve: {
                itemGroupModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "itemGroupModule",
                        files: ['app/masters/itemGroup/itemGroupCtrl.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //item Path Declarartion
        .state('item', {
            url: '/item',
            templateUrl: 'app/masters/item/item.html?v=' + ENV.version,
            cache: false,
            controller: 'ItemCtrl as itm',
            resolve: {
                itemModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "itemModule",
                        files: ['app/masters/item/itemCtrl.min.js?v=' + ENV.version,
                        'common/services/item/itemResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //Country Path Declarartion
        .state('country', {
            url: '/country',
            templateUrl: 'app/masters/country/country.html?v=' + ENV.version,
            cache: false,
            controller: 'CountryCtrl as cntry',
            resolve: {
                countryModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "countryModule",
                        files: ['app/masters/country/countryCtrl.min.js?v=' + ENV.version,
                        'common/services/country/countryResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('vvProduct', {
            url: '/vvProduct',
            templateUrl: 'app/masters/vvproduct/vvproduct.html?v=' + ENV.version,
            cache: false,
            controller: 'vvProductCtrl as prod',
            resolve: {
                productModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "productModule",
                        files: ['app/masters/vvProduct/vvProductCtrl.min.js?v=' + ENV.version,
                        'common/services/vvProduct/vvProductResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        }).state('VVPackage', {
            url: '/VVPackage',
            templateUrl: 'app/masters/vvPackage/vvPackage.html?v=' + ENV.version,
            cache: false,
            controller: 'vvPackageCtrl as prod',
            resolve: {
                productModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "productModule",
                        files: ['app/masters/vvPackage/vvPackageCtrl.min.js?v=' + ENV.version,
                        'common/services/vvPackage/vvPackageResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Order Status Path Declarartion
        .state('orderStatus', {
            url: '/orderStatus',
            templateUrl: 'app/masters/orderStatus/orderStatus.html?v=' + ENV.version,
            cache: false,
            controller: 'OrderStatusCtrl as ordst',
            resolve: {
                orderStatusModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "orderStatusModule",
                        files: ['app/masters/OrderStatus/orderStatusCtrl.min.js?v=' + ENV.version,
                        'common/services/orderStatusResource/orderStatusResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Accessories Path Declarartion
        .state('accessory', {
            url: '/accessory',
            templateUrl: 'app/masters/accessory/accessory.html?v=' + ENV.version,
            cache: false,
            controller: 'AccessoryCtrl as acc',
            resolve: {
                accessoryModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "accessoryModule",
                        files: ['app/masters/accessory/accessoryCtrl.min.js?v=' + ENV.version,
                        'common/services/accessory/accessoryResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //State Path Declarartion
        .state('state', {
            url: '/state',
            templateUrl: 'app/masters/state/state.html?v=' + ENV.version,
            cache: false,
            controller: 'StateCtrl as st',
            resolve: {
                stateModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "stateModule",
                        files: ['app/masters/state/stateCtrl.min.js?v=' + ENV.version,
                        'common/services/state/stateResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Paragon Price List Path Declarartion
        .state('paragonPriceList', {
            url: '/paragonPriceList',
            templateUrl: 'app/masters/paragonPriceList/paragonPriceList.html?v=' + ENV.version,
            cache: false,
            controller: 'ParagonPriceListCtrl as ppList',
            resolve: {
                paragonModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "paragonModule",
                        files: ['app/masters/paragonPriceList/paragonPriceListCtrl.min.js?v=' + ENV.version,
                        'common/services/paragonPriceList/paragonPriceListResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //product code Path Declarartion
        .state('productCode', {
            url: '/product-code',
            templateUrl: 'app/masters/productCode/productCode.html?v=' + ENV.version,
            cache: false,
            controller: 'ProductCodeCtrl as prdcd',
            resolve: {
                productCodeModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "productCodeModule",
                        files: ['app/masters/ProductCode/productCodeCtrl.min.js?v=' + ENV.version,
                        'common/services/productCode/productCodeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //ProductGroup
        .state('productGroup', {
            url: '/product-group',
            templateUrl: 'app/masters/productGroup/productGroup.html?v=' + ENV.version,
            cache: false,
            controller: 'ProductGroupCtrl as prdctGrp',
            resolve: {
                prdctGrpModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "prdctGrpModule",
                        files: ['app/masters/productGroup/productGroupCtrl.min.js?v=' + ENV.version,
                        'common/services/productGroup/productGroupResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Payment Method Path Declarartion
        .state('paymentMethod', {
            url: '/payment-method',
            templateUrl: 'app/masters/paymentMethod/paymentMethod.html?v=' + ENV.version,
            cache: false,
            controller: 'PaymentMethodCtrl as pMethod',
            resolve: {
                colorNameModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "colorNameModule",
                        files: ['app/masters/paymentMethod/paymentMethodCtrl.min.js?v=' + ENV.version,
                        'common/services/paymentMethod/paymentMethodResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //lensType Path Declarartion
        .state('lensType', {
            url: '/lens-type',
            templateUrl: 'app/masters/lensType/lensType.html?v=' + ENV.version,
            cache: false,
            controller: 'LensTypeCtrl as ltyp',
            resolve: {
                lensTypeModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "lensTypeModule",
                        files: ['app/masters/lensType/lensTypeCtrl.min.js?v=' + ENV.version,
                        'common/services/lensType/lensTypeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })



        //shipping Method Path Declarartion
        .state('shippingMethod', {
            url: '/shipping-method',
            templateUrl: 'app/masters/shippingMethod/shippingMethod.html?v=' + ENV.version,
            cache: false,
            controller: 'ShippingMethodCtrl as shpmd',
            resolve: {
                shippingMethodModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "shippingMethodModule",
                        files: ['app/masters/shippingMethod/shippingMethodCtrl.min.js?v=' + ENV.version,
                        'common/services/shippingMethod/shippingMethodResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //color Path Declarartion
        .state('colorName', {
            url: '/color-name',
            templateUrl: 'app/masters/colorName/colorName.html?v=' + ENV.version,
            cache: false,
            controller: 'ColorNameCtrl as clr',
            resolve: {
                colorNameModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "colorNameModule",
                        files: ['app/masters/colorName/colorNameCtrl.min.js?v=' + ENV.version,
                        'common/services/colorName/colorNameResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //Material Profile Path Declarartion
        .state('materialProfile', {
            url: '/material-profile',
            templateUrl: 'app/masters/materialProfile/materialProfile.html?v=' + ENV.version,
            cache: false,
            controller: 'MaterialProfileCtrl as matprf',
            resolve: {
                materialProfileMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "materialProfileMethod",
                        files: ['app/masters/materialProfile/materialProfileCtrl.min.js?v=' + ENV.version,
                        'common/services/materialProfile/materialProfileResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // service Number declaration
        .state('serviceNumber',
            {

                url: '/service-number',
                templateUrl: 'app/masters/serviceNumber/serviceNumber.html?v=' + ENV.version,
                cache: false,
                controller: 'ServiceNumberCtrl as sn',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/serviceNumber/ServiceNumberCtrl.min.js?v=' + ENV.version,
                            'common/services/serviceNumber/serviceNumberResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        // service Number declaration
        .state('smapNumber',
            {

                url: '/smapNumber',
                templateUrl: 'app/masters/smapNumber/smapNumber.html?v=' + ENV.version,
                cache: false,
                controller: 'SMapNumberCtrl as sm',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: "profileModule",
                            files: ['app/masters/smapNumber/smapNumberCtrl.min.js?v=' + ENV.version,
                            'common/services/smapNumber/smapNumberResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        // Desn cost
        .state('desnCost',
            {

                url: '/desnCost',
                templateUrl: 'app/masters/desnCost/desnCost.html?v=' + ENV.version,
                cache: false,
                controller: 'DesnCostCtrl as dc',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: "desnCostModule",
                            files: ['app/masters/desnCost/desnCostCtrl.min.js?v=' + ENV.version,
                            'common/services/desnCost/desnCostResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        //colorCode
        .state('colorCode',
            {

                url: '/colorCode',
                templateUrl: 'app/masters/colorCode/colorCode.html?v=' + ENV.version,
                cache: false,
                controller: 'ColorCodeCtrl as cc',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/colorCode/colorCodeCtrl.min.js?v=' + ENV.version,
                            'common/services/colorCode/colorCodeResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        // colorDetail

        .state('colorDetail',
            {
                url: '/colorDetail',
                templateUrl: 'app/masters/colorDetail/colorDetail.html?v=' + ENV.version,
                cache: false,
                controller: 'ColorDetailCtrl as cd',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/colorDetail/colorDetailCtrl.min.js?v=' + ENV.version,
                            'common/services/colorDetail/colorDetailResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        //reason Path Declarartion
        .state('reason', {
            url: '/reason',
            templateUrl: 'app/masters/reason/reason.html?v=' + ENV.version,
            cache: false,
            controller: 'ReasonCtrl as rea',
            resolve: {
                reasonMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "reasonMethod",
                        files: ['app/masters/reason/reasonCtrl.min.js?v=' + ENV.version,
                        'common/services/reason/reasonResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //smAP Item
        .state('smapItem',
            {

                url: '/smapItem',
                templateUrl: 'app/masters/smapItem/smapItem.html?v=' + ENV.version,
                cache: false,
                controller: 'SMapItemCtrl as sp',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/smapItem/smapItemCtrl.min.js?v=' + ENV.version,
                            'common/services/smapItem/smapItemResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //Feature
        .state('feature',
            {

                url: '/feature',
                templateUrl: 'app/masters/features/features.html?v=' + ENV.version,
                cache: false,
                controller: 'FeatureCtrl as fea',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/features/featuresCtrl.min.js?v=' + ENV.version,
                            'common/services/feature/featureResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        //// payment Page ========================================
        .state('payment', {
            url: '/payment/?id',
            templateUrl: 'app/views/template/payment/payment.html?v=' + ENV.version,
            controller: 'PaymentCtrl as vm',
            resolve:
            {
                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/payment/PaymentCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //// PaymentHost Page ========================================
        .state('paymentHost', {
            url: '/paymentHost',/////:amount
            templateUrl: 'app/views/template/payment/paymentHostPage.html?v=' + ENV.version,
            controller: 'PaymentHostCtrl as rp',
            resolve:
            {
                //paymentResource: "paymentResource",
                //paymentResponse: function (paymentResource, $stateParams) {
                //    //var practiceId = $stateParams.amount;
                //    alert($stateParams.amount)
                //    return paymentResource.getTokenForPayment({ amount: $stateParams.amount }).$promise;
                //},
                paymentHostModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/payment/PaymentHostCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //// PaymentHost Page ========================================
        .state('paymentResponse', {
            url: '/paymentResponse',
            templateUrl: 'app/views/template/payment/iframeCommunicator.html?v=' + ENV.version,
            controller: 'IframeCommunicatorCtrl',
            resolve:
            {
                paymentResponseModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/payment/iframeCommunicator.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //// paymentCompletedOrder Page ========================================
        .state('paymentCompletedOrders', {
            url: '/paymentCompletedOrders',
            templateUrl: 'app/views/template/payment/paymentCompletedOrders.html?v=' + ENV.version,
            controller: 'PaymentCompletedOrdersCtrl',
            resolve:
            {
                paymentCompletedOrderModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/payment/paymentCompletedOrdersCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        /// <reference path="views/template/dashboard/dashboard.html" />
        /// <reference path="views/template/dashboard/dashboardCtrl.min.js" />
        /// <reference path="../common/services/dashboard/dashboardresource.min.js" />


        //dashboard Form Page ========================================
        .state('dashboard',
            {
                url: '/dashboard',
                templateUrl: 'app/views/template/dashboard/dashboard.html?v=' + ENV.version,
                cache: false,
                controller: 'DashboardCtrl as db',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/dashboard/dashboardCtrl.min.js?v=' + ENV.version,
                            'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        ////////////////////////////////////// Component Pages ======================================== //////////////////////////////////////

        // datetime picker Page ========================================
        .state('datePicker', {
            url: '/template/components/datePicker',
            templateUrl: 'app/views/template/datepicker/datepicker.html?v=' + ENV.version,
            cache: false,
            controller: 'DatePickerCtrl as dateCtrl'
        })


        // complaintForm Page ========================================

        .state('complaintList', {
            url: '/complaintList',
            templateUrl: 'app/views/template/complaintForm/complaintList.html?v=' + ENV.version,
            // cache: false,
            controller: 'ComplaintListCtrl as cl',
            params: {
                page: {
                    paginationPageSize: 10,
                    paginationCurrentPage: 1,
                    sortName: null,
                    sortDirection: 'DESC'
                },
                search: {
                    global: ''
                }
            },
            resolve:
            {

                complaintModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "complaintModule",
                        files: ['app/views/template/complaintForm/complaintListCtrl.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('complaintForm', {
            url: '/complaintForm/:complaintId',
            templateUrl: 'app/views/template/complaintForm/complaintForm.html?v=' + ENV.version,
            cache: false,
            controller: 'complaintFormCtrl as cf',
            resolve:
            {
                complaintResource: "complaintResource",
                complObj: function (complaintResource, $stateParams) {
                    var complaintId = $stateParams.complaintId;
                    return complaintResource.getComplaintById({ complaintId: complaintId }).$promise;
                },
                complModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "complModule",
                        files: ['app/views/template/complaintForm/complainFormCtrl.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        //  distributor discount

        .state('distributorDiscountList', {
            url: '/distributorDiscountList',
            templateUrl: 'app/views/template/distributorDiscount/distributorDiscountList.html?v=' + ENV.version,
            // cache: false,
            controller: 'distributorDiscountListCtrl as dl',
            resolve:
            {

                distriDiscountList: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "distriDiscountList",
                        files: ['app/views/template/distributorDiscount/distributorDiscountListCtrl.min.js?v=' + ENV.version,
                        'common/services/distributorDiscount/distributorDiscountResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('distributorDiscountForm', {
            url: '/distributorDiscountForm/:distributorDiscountId',
            templateUrl: 'app/views/template/distributorDiscount/distributorDiscountForm.html?v=' + ENV.version,
            cache: false,
            controller: 'distributorDiscountFormCtrl as df',
            resolve:
            {
                distributorDiscountResource: "distributorDiscountResource",
                distributorDiscountObj: function (distributorDiscountResource, $stateParams) {
                    var distributorDiscountId = $stateParams.distributorDiscountId;
                    return distributorDiscountResource.getDistributorDiscountById({ id: distributorDiscountId }).$promise;
                },
                distributorDiscountModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "distributorDiscountModule",
                        files: ['app/views/template/distributorDiscount/distributorDiscountFormCtrl.min.js?v=' + ENV.version,
                        'common/services/distributorDiscount/distributorDiscountResource.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })


        // torn or broken lense
        .state('tornBrokenLensList', {
            url: '/tornBrokenLensList',
            templateUrl: 'app/views/template/tornBrokenLense/tornBrokenList.html?v=' + ENV.version,
            // cache: false,
            controller: 'tornBrokenListCtrl as tl',
            params: {
                page: {
                    paginationPageSize: 10,
                    paginationCurrentPage: 1,
                    sortName: null,
                    sortDirection: 'DESC'
                },
                search: {
                    global: ''
                }
            },
            resolve:
            {

                brokenModel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "brokenModel",
                        files: ['app/views/template/tornBrokenLense/tornBrokenListCtrl.min.js?v=' + ENV.version,
                        'common/services/tornBrokenLense/tornBrokenLenseResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })
        .state('tornBrokenForm', {
            url: '/tornBrokenForm/:id',
            templateUrl: 'app/views/template/tornBrokenLense/tornBrokenForm.html?v=' + ENV.version,
            cache: false,
            controller: 'tornBrokenFormCtrl as tf',
            resolve:
            {
                tornBrokenLenseResource: "tornBrokenLenseResource",
                tornBrokenObj: function (tornBrokenLenseResource, $stateParams) {
                    var id = $stateParams.id;
                    //if (id == 0) {
                    //    return null;
                    //}
                    return tornBrokenLenseResource.getId({ id: id }).$promise;
                },
                tornBroken: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "tornBroken",
                        files: ['app/views/template/tornBrokenLense/tornBrokenFormCtrl.min.js?v=' + ENV.version,
                        'common/services/tornBrokenLense/tornBrokenLenseResource.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConformlensResource.min.js?v=' + ENV.version,
                        'common/services/materialDetail/materialDetailResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })
        .state('tornBrokenAnalysisRpt', {
            url: '/tornBrokenAnalysisRpt',
            templateUrl: 'app/views/template/tornBrokenLense/tornBrokenAnalysisRpt.html?v=' + ENV.version,
            controller: 'tornBrokenAnalysisRptCtrl as tb',
            resolve: {
                InprocessNonConformAnalysisRptModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "InprocessNonConformAnalysisRptModule",
                        files: ['app/views/template/tornBrokenLense/tornBrokenAnalysisRptCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConformlensResource.min.js?v=' + ENV.version,
                        'common/services/version/versionResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version,
                        'common/services/tornBrokenLense/tornBrokenLenseResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }
        })


        // Device Failure Analysis Page ========================================
        .state('deviceFailureList', {
            url: '/deviceFailureList',
            templateUrl: 'app/views/template/deviceFailureAnalysis/deviceFailureList.html?v=' + ENV.version,
            // cache: false,
            controller: 'deviceFailureListCtrl as dl',
            params: {
                page: {
                    paginationPageSize: 10,
                    paginationCurrentPage: 1,
                    sortName: null,
                    sortDirection: 'DESC'
                },
                search: {
                    global: ''
                }
            },
            resolve:
            {

                dfModel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "dfModel",
                        files: ['app/views/template/deviceFailureAnalysis/deviceFailureListCtrl.min.js?v=' + ENV.version,
                        'common/services/deviceFailureAnalysis/deviceFailureAnalysisResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        .state('deviceFailureAnalysis', {
            url: '/deviceFailureAnalysis/:id',
            templateUrl: 'app/views/template/deviceFailureAnalysis/deviceFailureAnalysis.html?v=' + ENV.version,
            // cache: false,
            controller: 'DeviceFailureAnalysisCtrl as df',
            resolve:
            {
                deviceFailureAnalysisResource: "deviceFailureAnalysisResource",
                deviceFailureObj: function (deviceFailureAnalysisResource, $stateParams) {
                    var id = $stateParams.id;
                    return deviceFailureAnalysisResource.getId({ id: id }).$promise;
                },
                deviceFailure: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "deviceFailure",
                        files: ['app/views/template/deviceFailureAnalysis/deviceFailureAnalysisCtrl.min.js?v=' + ENV.version,
                        'common/services/deviceFailureAnalysis/deviceFailureAnalysisResource.min.js?v=' + ENV.version,
                        'common/services/complaint/complaintResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }
        })

        // dx udi Label Page ========================================
        .state('dxUdiLabel', {
            url: '/dx-udi/?isSquare',
            templateUrl: 'app/views/template/labels/dxUDILabel/dxUDILabel.html?v=' + ENV.version,
            controller: 'DxUdiLabelCtrl as dxUdi',
            resolve:
            {
                udiLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/dxUDILabel/dxUDILabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // Zebra dx udi Label Page ========================================
        .state('zeBradxUdiLabel', {
            url: '/zeBradxUdiLabel/?isSquare',
            templateUrl: 'app/views/template/labels/dxUDILabel/dxUDILabelZebra.html?v=' + ENV.version,
            controller: 'DxUdiLabelCtrl as dxUdi',
            resolve:
            {
                udiLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/dxUDILabel/dxUDILabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // info Label Page ========================================

        .state('infoLabel', {
            url: '/infoLabel',
            templateUrl: 'app/views/template/labels/infoLabel/infoLabel.html?v=' + ENV.version,
            controller: 'InfoLabelCtrl as infol',
            resolve:
            {
                infoLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/infoLabel/infoLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // info Label Page ========================================

        .state('infoLabelZebra', {
            url: '/infoLabelZebra',
            templateUrl: 'app/views/template/labels/infoLabel/infoLabelZebra.html?v=' + ENV.version,
            controller: 'InfoLabelCtrl as infol',
            resolve:
            {
                infoLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/infoLabel/infoLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // UDI Label Page ========================================
        .state('udiLabel', {
            url: '/udiLabel',
            templateUrl: 'app/views/template/labels/udiLabel/udiLabel.html?v=' + ENV.version,
            controller: 'UdiLabelCtrl as udil',
            resolve:
            {
                udiLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/udiLabel/udiLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // UDI Label Page ========================================
        .state('udiLabelZebra', {
            url: '/udiLabelZebra',
            templateUrl: 'app/views/template/labels/udiLabel/udiLabelZebra.html?v=' + ENV.version,
            controller: 'UdiLabelCtrl as udil',
            resolve:
            {
                udiLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/udiLabel/udiLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // Pouch Label Page ========================================
        .state('pouchLabel', {
            url: '/pouchLabel',
            templateUrl: 'app/views/template/labels/pouchLabel/pouchLabel.html?v=' + ENV.version,
            controller: 'PouchLabelCtrl as pouch',
            resolve:
            {
                pouchLabel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/pouchLabel/pouchLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // DFS SET ID Label Page ========================================
        .state('dxSetIdLabel', {
            url: '/dxSetIdLabel',
            templateUrl: 'app/views/template/labels/dxSetIdLabel/dxSetIdLabel.html?v=' + ENV.version,
            controller: 'DxSetIdCtrl as dxSet',
            resolve:
            {
                dfsSetID: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/dxSetIdLabel/dxSetIdLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // DFS SET ID Label Page ========================================
        .state('flatterLabel', {
            url: '/flatterLabel',
            templateUrl: 'app/views/template/labels/dxflatterLabel/flatterLabel.html?v=' + ENV.version,
            controller: 'FlatterLabelCtrl as flatLb',
            resolve:
            {
                flatter: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/dxflatterLabel/flatterLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        .state('gpDiagLabel', {
            url: '/gpDiagLabel',
            templateUrl: 'app/views/template/labels/gpDiag/gpDiag.html?v=' + ENV.version,
            controller: 'GpDiagCtrl as gpLb',
            resolve:
            {
                flatter: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/gpDiag/gpDiagCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })


        .state('topVialLabel', {
            url: '/topVialLabel/:id',
            templateUrl: 'app/views/template/labels/topVialLabel/topVialLabel.html?v=' + ENV.version,
            controller: 'TopVialLabelCtrl as tvLb',
            resolve:
            {
                flatter: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/topVialLabel/topVialLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        .state('softLensLabel', {
            url: '/softLensLabel',
            templateUrl: 'app/views/template/labels/softLensLabel/softLensLabel.html?v=' + ENV.version,
            controller: 'SoftLensLabelCtrl as sll',
            resolve:
            {
                flatter: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/labels/softLensLabel/softLensLabelCtrl.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        .state('inprocessNonConforming', {
            url: '/inprocessNonConforming',
            templateUrl: 'app/views/template/inprocessNonConforming/inprocessNonConforming.html?v=' + ENV.version,
            controller: 'InprocessNonConformingCtrl as ipnc',
            resolve: {
                inprocessNonConformingModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "inprocessNonConformingModule",
                        files: ['app/views/template/inprocessNonConforming/inprocessNonConformingCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConforminglensResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('inprocessnonconformanalysisrpt', {
            url: '/inprocessnonconformanalysisrpt',
            templateUrl: 'app/views/template/inprocessNonConforming/inprocessNonConformAnalysisRpt.html?v=' + ENV.version,
            controller: 'InprocessNonConformAnalysisRptCtrl as ipnc',
            resolve: {
                InprocessNonConformAnalysisRptModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "InprocessNonConformAnalysisRptModule",
                        files: ['app/views/template/inprocessNonConforming/inprocessNonConformAnalysisRptCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConformlensResource.min.js?v=' + ENV.version,
                        'common/services/version/versionResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('nonConformingLensLog', {
            url: '/nonConformingLensLog',
            templateUrl: 'app/views/template/nonConforminglens/nonConforminglenslog.html?v=' + ENV.version,
            controller: 'NonConforminglensLogCtrl as ncll',
            resolve: {
                nonConformingLensLogModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "nonConformingLensLogModule",
                        files: ['app/views/template/nonConforminglens/nonConforminglensLogCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConforminglensResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('nonConformLensLog', {
            url: '/nonconformlenslog',
            templateUrl: 'app/views/template/nonConforminglens/nonConformlenslog.html?v=' + ENV.version,
            controller: 'NonConformlensLogCtrl as ncll',
            resolve: {
                NonConformlensLogModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "NonConformlensLogModule",
                        files: ['app/views/template/nonConforminglens/nonConformlensLogCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConformlensResource.min.js?v=' + ENV.version,
                        'common/services/version/versionResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('nonConformingLens', {
            url: '/nonConformingLens',
            templateUrl: 'app/views/template/nonConforminglens/nonConforminglens.html?v=' + ENV.version,
            controller: 'NonConforminglensCtrl as ncl',
            resolve: {
                nonConformingLensModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "nonConformingLensModule",
                        files: ['app/views/template/nonConforminglens/nonConforminglensCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConforminglensResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('nonconformlens', {
            url: '/nonconformlens',
            templateUrl: 'app/views/template/nonConforminglens/nonconformlens.html?v=' + ENV.version,
            controller: 'NonConformLensCtrl as ncl',
            resolve: {
                nonConformLensModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "nonConformLensModule",
                        files: ['app/views/template/nonConforminglens/nonConformLensCtrl.min.js?v=' + ENV.version,
                        'common/services/nonConforminglens/nonConformlensResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        .state('lensPreference', {
            url: '/preference/:userPracticeId/:firstName/:lastName/:practiceName',
            templateUrl: 'app/views/template/addCustomer/lensOrderPreferenceForm.html?v=' + ENV.version,
            cache: false,
            controller: 'LensPreferenceCtrl as lp',
            resolve:
            {
                //orderPreferenceResource: "orderPreferenceResource",
                //preferences: function (orderPreferenceResource, $stateParams) {
                //    var userId = $stateParams.userId;
                //    return orderPreferenceResource.getPreferenceByUserId({ userId: userId }).$promise;
                //},
                lensPref: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "lensPref",
                        files: ['app/views/template/addCustomer/lensOrderPreferenceFormCtrl.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //desnCostDFS Path Declarartion
        .state('desnCostDFS', {
            url: '/desnCostDFS',
            templateUrl: 'app/masters/dencostDFS/dencostDFS.html?v=' + ENV.version,
            cache: false,
            controller: 'DencostDFSCtrl as dc',
            resolve: {
                desnCostDFSModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "desnCostDFSModule",
                        files: ['app/masters/dencostDFS/dencostDFSCtrl.min.js?v=' + ENV.version,
                        'common/services/dencostDFS/dencostDFSResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })





        //Rgp Design Page ========================================
        .state('rgpDesign',
            {
                url: '/rgp-design',
                templateUrl: 'app/masters/rgpDesign/rgpDesign.html?v=' + ENV.version,
                cache: false,
                controller: 'RgpDesignCtrl as rgpd',
                resolve:
                {
                    rgpDesignModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: "rgpDesignModule",
                            files: ['app/masters/rgpDesign/rgpDesignCtrl.min.js?v=' + ENV.version,
                            'common/services/rgpDesign/rgpDesignResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        //Rgp Design


        .state('rgpDesignEdit', {
            url: '/rgpDesignEdit/:rgpDesignId',
            templateUrl: 'app/masters/rgpDesign/rgpDesignForm.html?v=' + ENV.version,
            cache: false,
            controller: 'RgpDesignFormCtrl as rgpdsn',
            resolve:
            {
                rgpDesignResource: "rgpDesignResource",
                rgpDesign: function (rgpDesignResource, $stateParams) {
                    var rgpDesignId = $stateParams.rgpDesignId;
                    return rgpDesignResource.getRgpDesignById({ rgpDesignId: rgpDesignId }).$promise;
                },
                rgpDesignModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "rgpDesignModule",
                        files: ['app/masters/rgpDesign/rgpDesignFormCtrl.min.js?v=' + ENV.version,
                        'common/services/rgpDesign/rgpDesignResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Tint Path Declarartion
        .state('tint', {
            url: '/tint',
            templateUrl: 'app/masters/tint/tint.html?v=' + ENV.version,
            cache: false,
            controller: 'TintCtrl as tnt',
            resolve: {
                tintModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "tintModule",
                        files: ['app/masters/tint/tintCtrl.min.js?v=' + ENV.version,
                        'common/services/tint/tintResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //Tint Path Declarartion
        .state('tintCylinder', {
            url: '/tint/tintCylinder',
            templateUrl: 'app/masters/tintCylinder/tintCylinder.html?v=' + ENV.version,
            cache: false,
            controller: 'TintCylinderCtrl as tcy',
            resolve: {
                tintCylinderModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "tintCylinderModule",
                        files: ['app/masters/tintCylinder/tintCylinderCtrl.min.js?v=' + ENV.version,
                        'common/services/tint/tintResource.min.js?v=' + ENV.version,
                        'common/services/tintCylinder/tintCylinderResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //Discount  path Declaration................
        .state('discounts', {
            url: '/discounts',
            templateUrl: 'app/masters/discounts/discounts.html?v=' + ENV.version,
            cache: false,
            controller: 'DiscountCtrl as ds',
            resolve: {
                discountModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "discountModule",
                        files: ['app/masters/discounts/discountsCtrl.min.js?v=' + ENV.version,
                        'common/services/discounts/discountsResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        //lensParametersDFSC Design Page ========================================
        .state('lensParametersDFSC',
            {
                url: '/lensParametersDFSC',
                templateUrl: 'app/masters/lensParametersDFS/lensParametersDFS.html?v=' + ENV.version,
                cache: false,
                controller: 'LensParametersDFSCtrl as lp',
                resolve:
                {
                    rgpDesignModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: "rgpDesignModule",
                            files: ['app/masters/lensParametersDFS/lensParametersDFSCtrl.min.js?v=' + ENV.version,
                            'common/services/lensParametersDFS/lensParametersDFSCtrlResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //Material Lot Number Path Declarartion
        .state('materialLotNumber', {
            url: '/material-lot-number',
            templateUrl: 'app/masters/materialLotNumber/materialLotNumber.html?v=' + ENV.version,
            cache: false,
            controller: 'MaterialLotNumberCtrl as matNumb',
            resolve: {
                materialLotNumberMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "materialLotNumberMethod",
                        files: ['app/masters/materialLotNumber/materialLotNumberCtrl.min.js?v=' + ENV.version,
                        'common/services/materialLotNumber/materialLotNumberResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //RKon Path Declarartion
        .state('rkon', {
            url: '/rkon',
            templateUrl: 'app/masters/rkon/rkon.html?v=' + ENV.version,
            cache: false,
            controller: 'RkonCtrl as rk',
            resolve: {
                rkonMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "rkonMethod",
                        files: ['app/masters/rkon/rkonCtrl.min.js?v=' + ENV.version,
                        'common/services/rkon/rkonResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('rkonEdit', {
            url: '/rkonEdit/:rkonId',
            templateUrl: 'app/masters/rkon/rkonForm.html?v=' + ENV.version,
            cache: false,
            controller: 'RkonFormCtrl as rkn',
            resolve:
            {
                rkonResource: "rkonResource",
                rkon: function (rkonResource, $stateParams) {
                    var rkonId = $stateParams.rkonId;
                    return rkonResource.getById({ rkonId: rkonId }).$promise;
                },
                rkonModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "rkonModule",
                        files: ['app/masters/rkon/rkonFormCtrl.min.js?v=' + ENV.version,
                        'common/services/rkon/rkonResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })



        //  //Power Path Declarartion
        .state('power', {
            url: '/power',
            templateUrl: 'app/masters/power/power.html?v=' + ENV.version,
            cache: false,
            controller: 'PowerCtrl as pr',
            resolve: {
                powerMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "powerMethod",
                        files: ['app/masters/power/powerCtrl.min.js?v=' + ENV.version,
                        'common/services/power/powerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('powerEdit', {
            url: '/powerEdit/:powerId',
            templateUrl: 'app/masters/power/powerForm.html?v=' + ENV.version,
            cache: false,
            controller: 'PowerFormCtrl as pwr',
            resolve:
            {
                powerResource: "powerResource",
                powerData: function (powerResource, $stateParams) {
                    var powerId = $stateParams.powerId;
                    return powerResource.getById({ powerId: powerId }).$promise;
                },
                powerModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "powerModule",
                        files: ['app/masters/power/powerFormCtrl.min.js?v=' + ENV.version,
                        'common/services/power/powerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // dfs order return 
        .state('dfsOrderReturn', {
            url: '/dfs-order-return/:orderId',
            templateUrl: 'app/views/template/dfsOrderReturn/dfsOrderReturn.html?v=' + ENV.version,
            cache: false,
            controller: 'DfsOrderReturnCtrl as dfsRet',
            resolve:
            {
                dfsOrderReturnResource: "dfsOrderReturnResource",
                dfsReturnDetails: function (dfsOrderReturnResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    return dfsOrderReturnResource.getDfsReturnById({ orderId: orderId }).$promise;
                },
                dfsReturnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "dfsReturnModule",
                        files: ['app/views/template/dfsOrderReturn/dfsOrderReturnCtrl.min.js?v=' + ENV.version,
                        'common/services/dfsOrderReturn/dfsOrderReturnResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })



        //Material detail Path Declarartion
        .state('materialDetail', {
            url: '/material-detail',
            templateUrl: 'app/masters/materialDetail/materialDetail.html?v=' + ENV.version,
            cache: false,
            controller: 'MaterialDetailCtrl as matDet',
            resolve: {
                materialDetailMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "materialDetailMethod",
                        files: ['app/masters/materialDetail/materialDetailCtrl.min.js?v=' + ENV.version,
                        'common/services/materialDetail/materialDetailResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // material lot # queue Path Declarartion
        .state('materialLotNoQueue', {
            url: '/lot-no-queue',
            templateUrl: 'app/views/template/materialLotNoQueue/materialLotNoQueue.html?v=' + ENV.version,
            cache: false,
            controller: 'LotNoQueueCtrl as lotNoq',
            resolve: {
                lotNoQueueMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "lotNoQueueMethod",
                        files: ['app/views/template/materialLotNoQueue/materialLotNoQueueCtrl.min.js?v=' + ENV.version,
                        'common/services/lotNumberQueue/lotNoQueueResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // shipping queue Path Declarartion========== commented by shwetha on 02/07/2019 =======this is old shipping queue =========>>>
        //.state('shippingQueue', {
        //    url: '/shipping-queue',
        //    templateUrl: 'app/views/template/shippingQueue/shippingQueue.html?v=' + ENV.version,
        //    cache: false,
        //    controller: 'ShippingQueueCtrl as shipQueue',
        //    resolve: {
        //        lotNoQueueMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
        //            return $ocLazyLoad.load({
        //                name: "lotNoQueueMethod",
        //                files: ['app/views/template/shippingQueue/shippingQueueCtrl.min.js?v=' + ENV.version,
        //                    'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version,
        //                    'common/services/eye/eyeResource.min.js?v=' + ENV.version
        //                ]
        //            })
        //        }]
        //    }
        //})

        // shipping queue Path Declarartion=========== new shipping queue added on cr2 changes on 02/07/2019=============>>>>>>>>>>>
        .state('shippingQueue', {
            url: '/shipping-queue',
            templateUrl: 'app/views/template/shippingQueue/ShippingQueueNew.html?v=' + ENV.version,
            cache: false,
            controller: 'ShippingQueueNewCtrl as shipQueue',
            resolve: {
                lotNoQueueMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "lotNoQueueMethod",
                        files: ['app/views/template/shippingQueue/ShippingQueueNewCtrl.min.js?v=' + ENV.version,
                        'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version,
                        'common/services/label/labelResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                        'common/services/shippingMethod/shippingMethodResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // returned dfs Path Declarartion
        .state('inventoryDxOrder', {
            url: '/inventory-dx-order',
            templateUrl: 'app/views/template/inventoryDxOrder/inventoryDxOrder.html?v=' + ENV.version,
            cache: false,
            controller: 'InventoryDxCtrl as inventoryDx',
            resolve: {
                lotNoQueueMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "inventoryDxOrderMethod",
                        files: ['app/views/template/inventoryDxOrder/inventoryDxOrderCtrl.min.js?v=' + ENV.version,
                        'common/services/dfsOrderReturn/dfsOrderReturnResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })



        // evaluation dfs Path Declarartion
        .state('dxEvalutionResult', {
            url: '/evaluation-dx-order/:orderId',
            templateUrl: 'app/views/template/dxEvalutionResult/dxEvalutionResult.html?v=' + ENV.version,
            cache: false,
            controller: 'DxEvalutionResultCtrl as dxEval',
            resolve:
            {
                dfsOrderReturnResource: "dfsOrderReturnResource",
                dxEvalDetails: function (dfsOrderReturnResource, $stateParams) {
                    var orderId = $stateParams.orderId;
                    return dfsOrderReturnResource.getDxOrderReturnedById({ orderId: orderId }).$promise;
                },
                dxEvalModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "dxEvalModule",
                        files: ['app/views/template/dxEvalutionResult/dxEvalutionResultCtrl.min.js?v=' + ENV.version,
                        'common/services/dfsOrderReturn/dfsOrderReturnResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // workOrder Page ========================================
        .state('workOrder', {
            url: '/workOrder/:orderId',
            templateUrl: 'app/views/template/workOrder/WorkOrder.html?v=' + ENV.version,
            controller: 'WorkOrderCtrl',
            resolve:
            {
                //dfsOrderReturnResource: "dfsOrderReturnResource",
                //dxEvalDetails: function (dfsOrderReturnResource, $stateParams) {
                //    var orderId = $stateParams.orderId;
                //    return dfsOrderReturnResource.getDxOrderReturnedById({ orderId: orderId }).$promise;
                //},
                workOrder: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "workOrder",
                        files: ['app/views/template/workOrder/workOrderCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })    // VV workOrder Page ========================================
        .state('vvWorkOrder', {
            url: '/vvWorkOrder/:orderId',
            templateUrl: 'app/views/template/vvWorkOrder/vvWorkOrder.html?v=' + ENV.version,
            controller: 'vvWorkOrderCtrl',
            resolve:
            {
                workOrder: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "vvworkOrder",
                        files: ['app/views/template/vvWorkOrder/vvWorkOrderCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrderDetails/vvOrderDetailsResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })
        .state('axWorkOrder', {
            url: '/workOrder/:orderId',
            templateUrl: 'app/views/template/workOrder/axWorkOrder.html?v=' + ENV.version,
            controller: 'WorkOrderCtrl',
            resolve:
            {
                workOrder: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "AxworkOrder",
                        files: ['app/views/template/workOrder/workOrderCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        // returned dx ret log Path Declarartion
        .state('dxReturnLog', {
            url: '/dx-return-log',
            templateUrl: 'app/views/template/logs/dxReturnLog/dxReturnLog.html?v=' + ENV.version,
            cache: false,
            controller: 'DxReturnLogCtrl as dxRetLog',
            resolve: {
                lotNoQueueMethod: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "dxReturnLogMethod",
                        files: ['app/views/template/logs/dxReturnLog/dxReturnLogCtrl.min.js?v=' + ENV.version,
                        'common/services/dxReturnLog/dxReturnLogResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //distributor add ons Path Declarartion
        .state('distributorAddOn', {
            url: '/distributor-addd-on',
            templateUrl: 'app/masters/distributorAddOn/distributorAddOn.html?v=' + ENV.version,
            cache: false,
            controller: 'DistributorAddOnCtrl as distAddOn',
            resolve: {
                distributorAddOnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "distributorAddOnModule",
                        files: ['app/masters/distributorAddOn/distributorAddOnCtrl.min.js?v=' + ENV.version,
                        'common/services/distributorAddOn/distributorAddOnResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //Excell Import In lens parameter DFS
        .state('importExcel', {
            url: '/importExcel',
            templateUrl: 'app/masters/lensParametersDFS/importExcel/importExcel.html?v=' + ENV.version,
            cache: false,
            controller: 'ImportExcelListCtrl as vm',
            resolve: {
                importExcel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "importExcel",
                        files: ['app/masters/lensParametersDFS/importExcel/importExcelCtrl.min.js?v=' + ENV.version,
                        'common/services/lensParametersDFS/lensParametersDFSCtrlResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // orders under manufacturing and final inspection
        .state('orderlistForShippingQueue', {
            url: '/orderlistForShipping/:accNo/:addressId',
            templateUrl: 'app/views/template/orderListForShipping/orderListForShipping.html?v=' + ENV.version,
            cache: false,
            controller: 'OrderListForShippingCtrl as ordr',
            resolve:
            {
                orderListShippingModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderListShippingModule',
                        files: ['app/views/template/orderListForShipping/orderListForShippingCtrl.min.js?v=' + ENV.version,
                        'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // smap order Page ========================================
        .state('smapOrder', {
            url: '/icontour-order/:smapOrderId',
            templateUrl: 'app/views/template/smapOrder/smapOrder.html?v=' + ENV.version,
            cache: false,
            controller: 'SmapOrderCtrl as smap',
            resolve:
            {
                smapOrderResource: "smapOrderResource",
                smapOrder: function (smapOrderResource, $stateParams) {
                    var smapOrderId = $stateParams.smapOrderId
                    return smapOrderResource.getSmapOrderById({ smapOrderId: smapOrderId }).$promise;
                },
                smapModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/smapOrder/smapOrderCtrl.min.js?v=' + ENV.version,
                        'common/services/smapOrder/smapOrderResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // smap order List Page ========================================
        .state('smapOrderList', {
            url: '/icontour-orders',
            templateUrl: 'app/views/template/smapOrder/smapOrderList/smapOrderList.html?v=' + ENV.version,
            cache: false,
            controller: 'SmapOrderListCtrl as smapOrders',
            resolve:
            {
                smapOrdersModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'smapOrdersModule',
                        files: ['app/views/template/smapOrder/smapOrderList/smapOrderListCtrl.min.js?v=' + ENV.version,
                        'common/services/smapOrder/smapOrderResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // Smap invoice ========================================
        .state('sMapInvoice', {
            url: '/icontour-invoice/:smapOrderId/:id',
            templateUrl: 'app/views/template/invoice/sMapInvoice.html?v=' + ENV.version,
            cache: false,
            controller: 'SMapInvoiceCtrl as smapInv',
            resolve:
            {
                smapOrderResource: "smapOrderResource",
                smapInvoice: function (smapOrderResource, $stateParams, $location, $rootScope) {
                    var qs = $location.search();
                    if (qs != null && qs.flag == 1) {
                        $rootScope.noNav = false;
                    }
                    var smapOrderId = $stateParams.smapOrderId;
                    var id = $stateParams.id;
                    return smapOrderResource.getInvoice({ smapOrderId: smapOrderId }).$promise;
                },
                smapModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/invoice/sMapInvoiceCtrl.min.js?v=' + ENV.version,
                        'common/services/smapOrder/smapOrderResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        // Smap Order details Page ========================================
        .state('sMap3DViewDetails', {
            url: '/icontour-order-details/:smapOrderId',
            templateUrl: 'app/views/template/smapOrder/sMapOdetails/sMapDetails.html?v=' + ENV.version,
            controller: 'SMapDetailsCtrl as smapDet',
            resolve:
            {
                smapOrderResource: "smapOrderResource",
                smapOrderDetails: function (smapOrderResource, $stateParams) {
                    var smapOrderId = $stateParams.smapOrderId
                    return smapOrderResource.getSmapOrderById({ smapOrderId: smapOrderId }).$promise;
                },
                smapModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/smapOrder/sMapOdetails/sMapDetailsCtrl.min.js?v=' + ENV.version,
                        'common/services/smapOrder/smapOrderResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // quote Form Page ========================================


        .state('quoteList', {
            url: '/quoteList',
            templateUrl: 'app/views/template/quoteForm/quoteList.html?v=' + ENV.version,
            // cache: false,
            controller: 'QuoteListCtrl as ql',
            resolve:
            {
                quoteListModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "quoteListModule",
                        files: ['app/views/template/quoteForm/quoteListCtrl.min.js?v=' + ENV.version,
                        'common/services/quoteForm/quoteResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('quoteForm', {
            url: '/quoteForm/:id',
            templateUrl: 'app/views/template/quoteForm/quoteForm.html?v=' + ENV.version,
            cache: false,
            controller: 'QuoteFormCtrl as qf',
            resolve:
            {
                quoteForm: "quoteResource",
                quoteForm: function (quoteResource, $stateParams) {
                    var id = $stateParams.id;
                    return quoteResource.getById({ id: id }).$promise;
                },
                quoteModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "quoteModule",
                        files: ['app/views/template/quoteForm/quoteFormCtrl.min.js?v=' + ENV.version,
                        'common/services/quoteForm/quoteResource.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })


        // --------path for orders based on practice ----------//
        .state('ordersOnPractice',
            {
                url: '/order-practice/:practiceId/:orderType',
                templateUrl: 'app/views/template/customerInfo/customerOrders.html?v=' + ENV.version,
                cache: false,
                controller: 'CustomerOrdersCtrl as cuOrder',
                resolve:
                {
                    function($stateParams) {
                        var practiceId = $stateParams.practiceId;
                    },
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/customerInfo/customerOrdersCtrl.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version,
                            'common/services/vvOrder/vvOrderResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //----------------------path for rx colors----------------------------//       
        .state('rxColor', {
            url: '/rx-color',
            templateUrl: 'app/masters/rxColor/rxColor.html?v=' + ENV.version,
            cache: false,
            controller: 'RxColorCtrl as rxClr',
            resolve:
            {
                contactModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'contactModule',
                        files: ['app/masters/rxColor/rxColorCtrl.min.js?v=' + ENV.version,
                        'common/services/rxColor/rxColorResource.min.js?v=' + ENV.version,
                        'common/services/materialLotNumber/materialLotNumberResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //---------------------------path for package----------------------//
        .state('package',
            {
                url: '/package',
                templateUrl: 'app/masters/package/package.html?v=' + ENV.version,
                cache: false,
                controller: 'PackageCtrl as pc',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/package/packageCtrl.min.js?v=' + ENV.version,
                            'common/services/package/packageResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //---------------------------path for  page----------------------//
        .state('paymentCardDetails',
            {
                url: '/paymentScreen/:id',
                templateUrl: 'app/views/template/payment/paymentCardDeatils/paymentCardDetails.html?v=' + ENV.version,
                cache: false,
                controller: 'paymentCardDetailsCtrl as rp',
                resolve:
                {
                    paymentResource: "paymentResource",
                    paymentProfiles: function (paymentResource, $stateParams) {
                        var id = $stateParams.id;
                        return paymentResource.getCustomerProfile({ id: id }).$promise;
                    },
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            name: "profileModule",
                            files: ['app/views/template/payment/paymentCardDeatils/paymentCardDetailsCtrl.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        //statement//
        .state('printStatement',
            {
                url: '/printStatement',
                templateUrl: 'app/views/template/invoice/invStmt.html?v=' + ENV.version,
                cache: false,
                controller: 'InvStmtCtrl as st',
                resolve:
                {
                    eyeResource: "eyeResource",
                    statements: function (paymentResource, $localStorage) {
                        const statementParam = $localStorage.statementParam;
                        return paymentResource.getStatement(statementParam).$promise;
                    },
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/invoice/invStmtCtrl.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        .state('statement',
            {
                url: '/statement',
                templateUrl: 'app/views/template/invoice/searchStmt.html?v=' + ENV.version,
                cache: false,
                controller: 'SearchStmtCtrl as st',
                resolve:
                {
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/invoice/searchStmtCtrl.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        .state('subCustomers', {
            url: '/subCustomers/:practiceId/:accountNo',
            templateUrl: 'app/views/template/addCustomer/subCustomer/subCustomer.html?v=' + ENV.version,
            cache: false,
            controller: 'SubCustomerCtrl as cc',
            resolve:
            {

                subCust: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "subCust",
                        files: ['app/views/template/addCustomer/subCustomer/subCustomerCtrl.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //--------------dfs QA inspection form-----------//    

        .state('dfsQAInspectionForm', {
            url: '/dfs-QA-Inspection-Form/:orderId',
            templateUrl: 'app/views/template/dfsOrder/dfsInspectionForm/dfsQAInspectionForm.html?v=' + ENV.version,
            controller: 'DfsQAInspectionFormCtrl as qaInspection',
            resolve:
            {
                dfsOrderResource: "dfsOrderResource",
                dxOrder: function (dfsOrderResource, $stateParams) {
                    var orderId = $stateParams.orderId
                    return dfsOrderResource.getDxOrder({ orderId: orderId }).$promise;
                },
                dxOrderModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/dfsOrder/dfsInspectionForm/dfsQAInspectionFormCtrl.min.js?v=' + ENV.version,
                        'common/services/dfsOrder/dfsOrderResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })


        //---------------------------path for refund page----------------------//
        .state('refundScreen',
            {
                url: '/refundScreen/?id',
                templateUrl: 'app/views/template/payment/refund/refund.html?v=' + ENV.version,
                cache: false,
                controller: 'refundCtrl as vm',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/payment/refund/refundCtrl.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version,
                            'common/services/customer/customerResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        .state('specrtumSearch',
            {
                url: '/specrtum-search',
                templateUrl: 'app/views/template/dashboard/spectrum/spectrumSearch.html?v=' + ENV.version,
                cache: false,
                controller: 'SpecrtumSearchCtrl as search',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/dashboard/spectrum/spectrumSearchCtrl.min.js?v=' + ENV.version,
                            'common/services/orderpreviewreport/orderpreviewreportresource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        //--------------spectrum wip report-----------//    

        .state('rptSpectrum', {
            url: '/rptSpectrum/?value',
            templateUrl: 'app/views/template/rptSpectrum/rptSpectrum.html?v=' + ENV.version,
            controller: 'RptSpectrumCtrl as rptSpect',
            resolve:
            {
                rptSpecModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/rptSpectrum/rptSpectrumCtrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }

        })

        //--------------dfs QA inspection form-----------//    

        //----------credit memo-----------------//
        .state('creditMemo', {
            url: '/creditMemo/:orderId/:lensId/:isAcm',
            templateUrl: 'app/views/template/invoice/creditMemo.html?v=' + ENV.version,
            controller: 'CreditMemoCtrl as cm',
            resolve:
            {
                orderReturnResource: "orderReturnResource",
                creditMemo: function (orderReturnResource, $stateParams, $location, $rootScope) {
                    var qs = $location.search();
                    if (qs != null && qs.flag == 1) {
                        $rootScope.noNav = false;
                    }
                    var orderId = $stateParams.orderId;
                    var lensId = $stateParams.lensId;
                    var isAcm = $stateParams.isAcm != null ? $stateParams.isAcm : false;
                    return orderReturnResource.getCreditMemoDetails({ orderId: orderId, lensId: lensId, isAcm: isAcm }).$promise;
                },
                creditMemoModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/invoice/creditMemoCtrl.min.js?v=' + ENV.version,
                        'common/services/orderReturn/orderReturnResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })

        .state('openBalanceReport',
            {
                url: '/openBalanceReport/:acctNo',
                templateUrl: 'app/views/template/payment/openBalanceReport/openBalanceReport.html?v=' + ENV.version,
                cache: false,
                controller: 'openBalanceReportCtrl as vm',
                resolve:
                {
                    paymentResource: "paymentResource",
                    openBalanceRpt: function (paymentResource, $stateParams, $location, $rootScope) {
                        var qs = $location.search();
                        if (qs != null && qs.flag == 1) {
                            $rootScope.noNav = false;
                        }
                        var _acctNo = $stateParams.acctNo;
                        return paymentResource.getOpenBalance({ acctNo: _acctNo }).$promise;
                    },
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/payment/openBalanceReport/openBalanceReportCtrl.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version

                            ]
                        })
                    }]
                }
            })

        //-------auto pay customers----------//
        .state('autoPayReport',
            {
                url: '/autoPayReport',
                templateUrl: 'app/views/template/payment/autoPayCustomersReport/autoPayCustomersReport.html?v=' + ENV.version,
                cache: false,
                controller: 'autoPayCustomersCtrl as vm',
                resolve:
                {
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/payment/autoPayCustomersReport/autoPayCustomersCtrl.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version

                            ]
                        })
                    }]
                }
            })
        .state('onHoldReport',
            {
                url: '/onholdreport',
                templateUrl: 'app/views/template/reports/onHoldReport/onHoldReport.html?v=' + ENV.version,
                cache: false,
                controller: 'onHoldReportCtrl as ohr',
                resolve:
                {
                    paymentModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: [
                                'app/views/template/reports/onHoldReport/onHoldReport.min.js?v=' + ENV.version,
                                'common/services/reports/onHoldReportResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        .state('onFileReport',
            {
                url: '/onfilereport',
                templateUrl: 'app/views/template/reports/onFileReport/onFileReport.html?v=' + ENV.version,
                cache: false,
                controller: 'onFileReportCtrl as ofr',
                resolve:
                {
                    paymentModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: [
                                'app/views/template/reports/onFileReport/onFileReport.min.js?v=' + ENV.version,
                                'common/services/reports/onFileReportResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        //-------overdue----------//
        .state('overDueReport',
            {
                url: '/overDueReport',
                templateUrl: 'app/views/template/payment/overDueReport/overDueReport.html?v=' + ENV.version,
                cache: false,
                controller: 'overDueReportCtrl as vm',
                resolve:
                {
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/payment/overDueReport/overDueReportCtrl.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version

                            ]
                        })
                    }]
                }
            })
        .state('version',
            {
                url: '/version',
                templateUrl: 'app/masters/version/version.html?v=' + ENV.version,
                cache: false,
                controller: 'VersionCtrl as vn',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/version/versionCtrl.min.js?v=' + ENV.version,
                            'common/services/version/versionResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        .state('orderHeader', {
            url: '/order-header',
            templateUrl: 'app/views/common/orderHeader.html?v=' + ENV.version,
            cache: false,
            controller: 'HeaderCtrl as hdr',
        })
        /// <reference path="" />
        .state('tablePagination', {
            url: '/table-pagination',
            templateUrl: 'app/views/common/tablePagination.html?v=' + ENV.version,
            cache: false,
            //controller: 'DxReturnLogCtrl as dxRetLog',


        })

        .state('log', {
            url: '/log',
            //templateUrl: 'app/views/template/logs/log.html?v=' + ENV.version,
            templateUrl: 'app/views/template/logs/tornBrokenLensLog/tornBrokenLensLog.html?v=' + ENV.version,
            cache: false,
            controller: 'TornBrokenLensLogCtrl as log',
            resolve:
            {

                profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/logs/tornBrokenLensLog/tornBrokenLensLogCtrl.min.js?v=' + ENV.version,
                        'common/services/tornBrokenLense/tornBrokenLenseResource.min.js?v=' + ENV.version

                        ]
                    })
                }]
            }

        })


        .state('orderPreviewReport',
            {
                url: '/preview-report',
                templateUrl: 'app/views/template/invoice/previewReport/orderPreviewReport.html?v=' + ENV.version,
                cache: false,
                controller: 'OrderPreviewReportCtrl as preview',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/invoice/previewReport/orderPreviewReportCtrl.min.js?v=' + ENV.version,
                            'common/services/orderPreviewReport/orderPreviewReportResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        .state('multipleInvoice',
            {
                url: '/multiple-Invoice/:isPackage?fromurl',
                templateUrl: 'app/views/template/invoice/multipleInvoice.html?v=' + ENV.version,
                cache: false,
                controller: 'MultipleInvoiceCtrl as multi',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/invoice/multipleInvoiceCtrl.min.js?v=' + ENV.version,
                            'common/services/orderPreviewReport/orderPreviewReportResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version,
                            'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                            'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        .state('multipleCreditmemo',
            {
                url: '/multiple-creditmemo',
                templateUrl: 'app/views/template/invoice/multipleCreditmemo.html?v=' + ENV.version,
                cache: false,
                controller: 'MultipleCreditmemoCtrl as multi',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/invoice/multipleCreditmemoCtrl.min.js?v=' + ENV.version,
                            'common/services/orderPreviewReport/orderPreviewReportResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version,
                            'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })


        //shipping discount Path Declarartion
        .state('shippingDiscount', {
            url: '/shippingDiscount',
            templateUrl: 'app/masters/shippingDiscount/shippingDiscount.html?v=' + ENV.version,
            cache: false,
            controller: 'shippingDiscountCtrl as vm',
            resolve: {
                countryModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "countryModule",
                        files: ['app/masters/shippingDiscount/shippingDiscountCtrl.min.js?v=' + ENV.version,
                        'common/services/shippingDiscount/shippingDiscountResource.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //------------return label page--------------//
        .state('returnShipment', {
            url: '/return-ship',
            templateUrl: 'app/views/template/shippingQueue/returnShipment/returnShipment.html?v=' + ENV.version,
            cache: false,
            controller: 'returnShipmentCtrl as vm',
            resolve: {
                retshipment: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "retshipment",
                        files: ['app/views/template/shippingQueue/returnShipment/returnShipmentCtrl.min.js?v=' + ENV.version,
                        'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //myApp.factory('productService', function () {
        //    var productList = [];

        //    var addProduct = function (newObj) {
        //        productList.push(newObj);
        //    };

        //    var getProducts = function () {
        //        return productList;
        //    };

        //    return {
        //        addProduct: addProduct,
        //        getProducts: getProducts
        //    };

        //--------------payment report------//
        .state('paymentReportSearch', {
            url: '/paymentReportSearch',
            templateUrl: 'app/views/template/payment/paymentReport/paymentReportSearch.html?v=' + ENV.version,
            controller: 'PaymentReportSearchCtrl as vm',
            resolve:
            {
                paymntRpt: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/payment/paymentReport/paymentReportSearchCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('paymentReport', {
            url: '/paymentReport',
            templateUrl: 'app/views/template/payment/paymentReport/paymentReport.html?v=' + ENV.version,
            controller: 'PaymentReportCtrl as vm',
            resolve:
            {
                paymntRpt: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: ['app/views/template/payment/paymentReport/paymentReportCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version,
                        'common/services/fetchControllerDataService.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //});

        // Work in progress Patient Lens ========================================

        .state('wippatientlanserpt', {
            url: '/wippatientlanserpt/:rptFlag',
            templateUrl: 'app/views/template/reports/europaPatientLens/europaPatientLns.html?v=' + ENV.version,
            cache: false,
            controller: 'EuropaPatientLnsCtrl as pl',
            resolve:
            {
                WIPPatientLanses: function (wipPatientLanseReportResource, $stateParams) {
                    let rptFlag = $stateParams.rptFlag.toLowerCase();
                    return wipPatientLanseReportResource.WIPPatientLansesRpt({ rptFlag: rptFlag }).$promise;
                },
                WIPPatientLanseRpt: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'WIPPatientLanseRpt',
                        files: [
                            'app/views/template/reports/europaPatientLens/europaPatientLnsCtrl.min.js?v=' + ENV.version,
                            'common/services/WIPPatientLanseReport/wipPatientLanseReportResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // order Entery Info ========================================
        .state('orderEntryInfo', {
            url: '/orderEntryInfo',
            templateUrl: 'app/views/template/reports/orderEntryInfo/orderEntryInfo.html?v=' + ENV.version,
            cache: false,
            controller: 'OrderEntryInfoCtrl as oei',
            resolve:
            {
                orderEntryModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderEntryModule',
                        files: [
                            'app/views/template/reports/orderEntryInfo/orderEntryInfoCtrl.min.js?v=' + ENV.version,
                            'common/services/orderEntryInfo/orderEntryInfoResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // Spectrum International Lot# Manifest ========================================
        .state('spectrumIntLotManifest', {
            url: '/spectrumIntLotManifest',
            templateUrl: 'app/views/template/reports/spectrumIntLotManifest/spectrumIntLotManifest.html?v=' + ENV.version,
            cache: false,
            controller: 'SpectrumIntLotManifestCtrl as silm',
            resolve:
            {
                SpectrumIntLotManifestModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'SpectrumIntLotManifestModule',
                        files: [
                            'app/views/template/reports/spectrumIntLotManifest/spectrumIntLotManifestCtrl.min.js?v=' + ENV.version,
                            'common/services/spectrumIntLotManifest/spectrumIntLotManifestResource.min.js?v=' + ENV.version,
                            'common/services/shippingQueue/shippingQueueResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Current Material List ========================================
        .state('currentMaterialList', {
            url: '/currentMaterialList',
            templateUrl: 'app/views/template/reports/currentMaterialList/currentMaterialList.html?v=' + ENV.version,
            cache: false,
            controller: 'CurrentMaterialListCtrl as cml',
            resolve:
            {
                currentMaterialRpt: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: [
                            'app/views/template/reports/currentMaterialList/currentMaterialListCtrl.min.js?v=' + ENV.version,
                            'common/services/materialDetail/materialDetailResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //Current Material List ========================================
        .state('invoiceQuickBooks', {
            url: '/invoiceQuickBooks/:isVV',
            templateUrl: 'app/views/template/reports/invoiceQuickBooks/invoiceQuickBooks.html?v=' + ENV.version,
            cache: false,
            controller: 'InvoiceQuickBooksCtrl as iqb',
            resolve:
            {
                InvoiceQuickBooksModel: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "InvoiceQuickBooksModel",
                        files: [
                            'app/views/template/reports/invoiceQuickBooks/invoiceQuickBooksCtrl.min.js?v=' + ENV.version,
                            'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        // XML batch report List Page ========================================
        .state('xmlBatchReport', {
            url: '/xmlBatchReport',
            templateUrl: 'app/views/template/reports/xmlbatchreport/xmlBatchReport.html?v=' + ENV.version,
            cache: false,
            controller: 'XmlBatchReportCtrl as xmlRep',
            resolve:
            {
                orderListModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderListModule',
                        files: ['app/views/template/reports/xmlbatchreport/xmlbatchreportctrl.min.js?v=' + ENV.version,
                        'common/services/eye/eyeResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('maps',
            {
                url: '/maps',
                templateUrl: 'app/views/template/dashboard/maps.html?v=' + ENV.version,
                cache: false,
                controller: 'mapsCtrl as mp',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/dashboard/mapsCtrl.min.js?v=' + ENV.version,
                            'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version,
                            'common/services/eye/eyeResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        //---printer master-------------//
        .state('printer', {
            url: '/printer',
            templateUrl: 'app/masters/printer/printer.html?v=' + ENV.version,
            cache: false,
            controller: 'PrinterCtrl as pr',
            resolve: {
                prModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "prModule",
                        files: ['app/masters/printer/printerCtrl.min.js?v=' + ENV.version,
                        'common/services/printer/printerResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        //--------------- VV orders---------------//
        .state('vvorderscreen', {
            url: '/vv-order/:orderId?orderStatusId?practiceId',
            templateUrl: 'app/views/template/vvOrders/vvOrder.html?v=' + ENV.version,
            cache: false,
            controller: 'VVOrderCtrl as vvOrder',
            resolve: {
                vvOrderResource: "vvOrderResource",
                order: function (vvOrderResource, $stateParams) {
                    var model = {};
                    model.OrderId = $stateParams.orderId !== "0" && $stateParams.orderId > 0 ? $stateParams.orderId : 0;
                    model.OrderStatusId = $stateParams.orderStatusId > 0 ? $stateParams.orderStatusId : 0;
                    return vvOrderResource.getVVOrderById(model).$promise;
                },
                vvOrder: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "prModule",
                        files: ['app/views/template/vvOrders/vvOrderCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrder/vvOrderResource.min.js?v=' + ENV.version,
                        'common/services/shippingMethod/shippingMethodResource.min.js?v=' + ENV.version,
                        'common/services/itemGroup/itemGroupResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }
        })

        // vv Orders List Page ========================================
        .state('vvorders', {
            url: '/vvorderlist',
            templateUrl: 'app/views/template/vvOrders/vvOrderList/vvOrderList.html?v=' + ENV.version,
            cache: false,
            controller: 'vvOrderListCtrl as vvOrdLst',
          
            resolve:
            {
                orderListModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderListModule',
                        files: ['app/views/template/vvOrders/vvOrderList/vvOrderListCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrder/vvOrderResource.min.js?v=' + ENV.version,
                        'common/services/vvOrderDetails/vvOrderDetailsResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }
        })

        .state('vvWipReport', {
            url: '/WIP-VibrantVue',
            templateUrl: 'app/views/template/reports/vibrantVue/wip/vibrantVueWip.html?v=' + ENV.version,
            cache: false,
            controller: 'vibrantVueWipCtrl as wip',
            resolve:
            {
                vvWip: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'vvWip',
                        files: [
                            'app/views/template/reports/vibrantVue/wip/vibrantVueWipCtrl.min.js?v=' + ENV.version,
                            'common/services/WIPPatientLanseReport/wipPatientLanseReportResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        /// <reference path="" />

        .state('vvorderReturn', {
            url: '/order-return/:orderId?returnStatusId',
            templateUrl: 'app/views/template/vvOrders/vvOrderReturn/vvOrderReturn.html?v=' + ENV.version,
            cache: false,
            controller: 'vvOrderReturnCtrl as odr',
            resolve:
            {
                orderReturnModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({

                        name: "orderReturnModule",
                        files: ['app/views/template/vvOrders/vvOrderReturn/vvOrderReturnCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrder/vvOrderReturnResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }
        })
        .state('vvCreditMemo', {
            url: '/vv-creditMemo/:orderId',
            templateUrl: 'app/views/template/vvOrders/vvOrderReturn/vvCreditMemo.html?v=' + ENV.version,
            controller: 'vvCreditMemoCtrl as cm',
            resolve:
            {
                creditMemo: function (vvOrderReturnResource, $stateParams, $location, $rootScope) {
                    var qs = $location.search();
                    if (qs != null && qs.flag == 1) {
                        $rootScope.noNav = false;
                    }
                    var orderId = $stateParams.orderId;
                    return vvOrderReturnResource.getCreditMemoDetails({ orderId: orderId }).$promise;
                },
                creditMemoModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/vvOrders/vvOrderReturn/vvCreditMemoCtrl.min.js?v=' + ENV.version,
                        'common/services/vvOrder/vvOrderReturnResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }

        })
        .state('vvDoctorReport', {
            url: '/DoctorReport-VibrantVue',
            templateUrl: 'app/views/template/reports/vibrantVue/vvDoctorReport/vvDoctorReport.html?v=' + ENV.version,
            cache: false,
            controller: 'vvDoctorCtrl as wip',
            resolve:
            {
                vvDoctor: function (wipPatientLanseReportResource, $stateParams) {
                    return wipPatientLanseReportResource.vvDoctorReport({ forExcelExport: false }).$promise;
                },
                vvWip: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'vvWip',
                        files: [
                            'app/views/template/reports/vibrantVue/vvDoctorReport/vvDoctorCtrl.min.js?v=' + ENV.version,
                            'common/services/WIPPatientLanseReport/wipPatientLanseReportResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('vvAutoShipReport', {
            url: '/AutoShip-VibrantVue',
            templateUrl: 'app/views/template/reports/vibrantVue/vvAutoShipReport/vvAutoShip.html?v=' + ENV.version,
            cache: false,
            controller: 'vvAutoShipCtrl as wip',
            resolve:
            {
                vvWip: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'vvWip',
                        files: [
                            'app/views/template/reports/vibrantVue/vvAutoShipReport/vvAutoShipCtrl.min.js?v=' + ENV.version,
                            'common/services/WIPPatientLanseReport/wipPatientLanseReportResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('prcReport', {
            url: '/prc-report/:prcReportId',
            templateUrl: 'app/views/template/customerInfo/prc/partnershipRevenueCredit.html?v=' + ENV.version,
            controller: 'partnershipRevenueCreditCtrl as cm',
            resolve:
            {
                creditMemo: function (customerResource, $stateParams, $location, $rootScope) {
                    var qs = $location.search();
                    if (qs != null && qs.flag == 1) {
                        $rootScope.noNav = false;
                    }
                    var prcReportId = $stateParams.prcReportId;
                    return customerResource.getPRCCreditMemo({ id: prcReportId }).$promise;
                },
                creditMemoModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "smapModule",
                        files: ['app/views/template/customerInfo/prc/partnershipRevenueCreditCtrl.min.js?v=' + ENV.version,
                        'common/services/customer/customerResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }

        })

        //vv order Entery Info ========================================
        .state('vvOrderInfoRpt', {
            url: '/vvOrderInfoRpt',
            templateUrl: 'app/views/template/reports/orderEntryInfo/vvOrderEntryInfo.html?v=' + ENV.version,
            cache: false,
            controller: 'VVOrderEntryInfoCtrl as vv',
            resolve:
            {
                orderEntryModule1: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'orderEntryModule1',
                        files: [
                            'app/views/template/reports/orderEntryInfo/vvOrderEntryInfoCtrl.min.js?v=' + ENV.version,
                            'common/services/orderEntryInfo/orderEntryInfoResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        //Design price  Page ========================================
        .state('designCostPrice',
            {

                url: '/designPrice',
                templateUrl: 'app/masters/designCostPrice/designCostPrice.html?v=' + ENV.version,
                cache: false,
                controller: 'DesignCostPriceCtrl as vm',
                resolve:
                {

                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/masters/designCostPrice/DesignCostPriceCtrl.min.js?v=' + ENV.version,
                            'common/services/designCost/designCostResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        .state('returntrackingreport',
            {
                url: '/returntrackingreport',
                templateUrl: 'app/views/template/reports/ReturnTrackingReport/returnTrackingReport.html?v=' + ENV.version,
                cache: false,
                controller: 'ReturnTrackingReportCtrl as rtr',
                resolve:
                {
                    profileModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/reports/ReturnTrackingReport/returnTrackingReportCtrl.min.js?v=' + ENV.version,
                            'common/services/orderReturn/orderReturnResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })

        .state('returnsTrendRpt', {
            url: '/returnsTrendRpt',
            templateUrl: 'app/views/template/returnsTrendAnalysis/returnsTrendAnalysisReport.html?v=' + ENV.version,
            cache: false,
            controller: 'returnsTrendAnalysisReportCtrl as rt',
            resolve:
            {
                orderEntryModule1: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'returnsModel',
                        files: [
                            'app/views/template/returnsTrendAnalysis/returnsTrendAnalysisReportCtrl.min.js?v=' + ENV.version,
                            'common/services/orderReturn/orderReturnResource.min.js?v=' + ENV.version,
                            'common/services/fetchControllerDataService.min.js?v=' + ENV.version,
                            'common/services/version/versionResource.min.js?v=' + ENV.version,
                        ]
                    })
                }]
            }
        })
        //Holiday List Path Declarartion
        .state('holiday', {
            url: '/holiday',
            templateUrl: 'app/masters/holidayList/holidayList.html?v=' + ENV.version,
            cache: false,
            controller: 'HolidayListCtrl as vm',
            resolve: {
                accessoryModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "holidayListModule",
                        files: ['app/masters/holidayList/holidayListCtrl.min.js?v=' + ENV.version,
                        'common/services/holidayList/holidayListResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('accountCm', {
            url: '/accountCm/:practiceId',
            templateUrl: 'app/views/template/payment/accountCreditMemo/accountCreditMemo.html?v=' + ENV.version,
            cache: false,
            controller: 'accountCreditMemoCtrl as vm',
            resolve: {
                accessoryModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "accountCMListModule",
                        files: ['app/views/template/payment/accountCreditMemo/accountCreditMemoCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })

        .state('arAging',
            {
                url: '/Ar-Aging',
                templateUrl: 'app/views/template/payment/arAgingReport/arAgingReport.html?v=' + ENV.version,
                cache: false,
                controller: 'arAgingReportCtrl as vm',
                resolve:
                {
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: ['app/views/template/payment/arAgingReport/arAgingReportCtrl.min.js?v=' + ENV.version,
                            'common/services/payment/paymentResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        .state('automateReport',
            {
                url: '/automate-report',
                templateUrl: 'app/views/template/reports/automateReport/automateReport.html?v=' + ENV.version,
                cache: false,
                controller: 'AutomateReportCtrl as ar',
                resolve:
                {
                    stmtModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load({
                            files: [
                                'app/views/template/reports/automateReport/automateReportCtrl.min.js?v=' + ENV.version,
                                'common/services/reports/automateReportResource.min.js?v=' + ENV.version
                            ]
                        })
                    }]
                }
            })
        .state('shippingScan', {
            url: '/shippingScan',
            templateUrl: 'app/views/template/reports/shippingScan/shippingScan.html?v=' + ENV.version,
            cache: false,
            controller: 'ShippingScanCtrl as shipScan',
            resolve:
            {
                ShippingScan: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "ShippingScan",
                        files: [
                            'app/views/template/reports/shippingScan/shippingScanCtrl.min.js?v=' + ENV.version,
                            'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('productionReport', {
            url: '/productionReport',
            templateUrl: 'app/views/template/reports/production/production.html?v=' + ENV.version,
            cache: false,
            controller: 'ProductionCtrl as prod',
            resolve:
            {
                Production: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "Production",
                        files: [
                            'app/views/template/reports/production/productionCtrl.min.js?v=' + ENV.version,
                            'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        .state('iContourSalesReport', {
            url: '/iContourSalesReport',
            templateUrl: 'app/views/template/reports/iContourSalesReport/iContourSalesReport.html?v=' + ENV.version,
            cache: false,
            controller: 'IContourSalesReportCtrl as icsr',
            resolve:
            {
                IContourSalesReport: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        files: [
                            'app/views/template/reports/iContourSalesReport/iContourSalesReportCtrl.min.js?v=' + ENV.version,
                            'common/services/dashboard/dashboardResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
        // Refund Receipt Page ========================================
        .state('refundReceipt', {
            url: '/refund-receipt',
            templateUrl: 'app/views/template/payment/refund/refundReceipt.html?v=' + ENV.version,
            controller: 'RefundReceiptCtrl as vm',
            resolve: {
                receiptmodule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: "receiptmodule",
                        files: ['app/views/template/payment/refund/refundReceiptCtrl.min.js?v=' + ENV.version,
                        'common/services/payment/paymentResource.min.js?v=' + ENV.version
                        ]
                    })
                }]
            }
        })
    //end



});

myApp.filter('searchfilter', function () {
    return function (input, query) {
        input = input.toString();
        var r = RegExp('(' + query + ')', 'i');
        return input.replace(r, '<strong>$1</strong>');
    }
});

myApp.config(function ($mdThemingProvider) {
    $mdThemingProvider.definePalette('primarytemplateColor', {
        '50': 'E1EEFA',
        '100': 'B3D6F3',
        '200': '81BAEC',
        '300': '4E9EE4',
        '400': '288ADE',
        '500': '0275D8',
        '600': '026DD4',
        '700': '0162CE',
        '800': '0158C8',
        '900': '0145BF',
        'A100': 'E7EEFF',
        'A200': 'B4CAFF',
        'A400': '81A6FF',
        'A700': '6894FF',
        'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
        // on this palette should be dark or light
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
            '200', '300', '400', 'A100'],
        'contrastLightColors': undefined    // could also specify this if default was 'dark'
    });
    $mdThemingProvider.theme('default')
        .primaryPalette('primarytemplateColor')
        .accentPalette('primarytemplateColor', {
            'default': '600'
        });
});

var isStateInProcess = true;
myApp.run(['$state', '$rootScope', '$injector', '$timeout', 'appSettings', 'userResource', 'toaster', '$location', function ($state, $rootScope, $injector, $timeout, appSettings, userResource, toaster, $location) {
    $rootScope.emailPattern = /^[a-zA-Z0-9_+]+((_|-|\.)[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.([a-zA-Z0-9]+((\.)[a-zA-Z0-9]+)*)$/;
    $rootScope.multiEmailPattern = /^([a-zA-Z0-9_]+((_|-|\.)[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.([a-zA-Z0-9]+((\.)[a-zA-Z0-9]+)*))+(?:, ?([a-zA-Z0-9_]+((_|-|\.)[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.([a-zA-Z0-9]+((\.)[a-zA-Z0-9]+)*))+)*$/;
    $rootScope.$on('$stateChangeStart', function (e, toState) {
        if ((toState.name == "login") || (toState.name == "register") || (toState.name == "confirmEmail") || (toState.name == "forgotPassword") || (toState.name == "resetUrl") || (toState.name == "") || (toState.name == "updateprofile") || (toState.name == "paymentHost") || (toState.name == "changePassWordEmail")) {
            $rootScope.noNav = false;
        } else {
            $rootScope.noNav = true;
        };

        if ((toState.name == "udiLabel") || (toState.name == "dxUdiLabel") || (toState.name == "pouchLabel") || (toState.name == "softLensLabel") || (toState.name == "flatterLabel") || (toState.name == "topVialLabel") || (toState.name == "dxSetIdLabel") || (toState.name == "gpDiagLabel") || (toState.name == "infoLabel") || (toState.name == "infoLabelZebra") || (toState.name == "udiLabelZebra") || (toState.name == "zeBradxUdiLabel")) {
            $rootScope.onlyLabels = false;
        } else {
            $rootScope.onlyLabels = true;
        };
        isStateInProcess = true;
        $rootScope.$broadcast('globalLoadingStart');
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        $rootScope.toState = toState;
        $rootScope.fromState = ((toState === fromState) ? $rootScope.fromState : fromState);
        /*added by shwetha 
         descp:to clear load latest sidebar based on version/cache data*/
        //var appSetting = $injector.get('appSettings');
        var userId = localStorage.getItem("userData");
        var version = localStorage.getItem("version");
        if (!ENV) {
            ENV.version = jsversion("");
        }

        if (version !== ENV.version) {
            userResource.getSidebarMenu({ id: userId }, function (res) {
                var res = JSON.parse(res.result);
                var list = [];
                res.forEach(function (v, i) {
                    if (v.IsParent == 1) {
                        list.push(v);
                        list[list.length - 1]["subTemplates"] = [];
                    }
                })
                list.forEach(function (v, i) {
                    var tmp = res.filter(x => x.IsParent == 0 && v.ParentId == x.ParentId);
                    list[i]['subTemplates'] = tmp;
                })
                localStorage.setItem("menu", list);
                localStorage.setItem("version", ENV.version);
                $rootScope.menu = list;
            });
        }
        /*end*/
        //#region
        //Desc:compare last password changed date with current date and notify to change the password to the user. 
        let isnotified = false;
        let lastNotifieddate = null;
        let notifyperiod = 24;// in hour

        var lastPasswordDate = localStorage.getItem("lastPasswordDate");
        var passwordExpiryNotificationDate = localStorage.getItem("passwordExpiryNotificationDate");
        if (!(localStorage.hasOwnProperty("isNotified"))) {
            localStorage.setItem("isNotified", false);
            localStorage.setItem("lastNotifieddate", null);
        }
        else {
            isnotified = localStorage.getItem("isNotified") == 'true' ? true : false;
            lastNotifieddate = new Date(localStorage.getItem("lastNotifieddate"));
        }

        let currentDate = new Date();
        if (lastPasswordDate != null) {
            let lastPWDDate = new Date(lastPasswordDate);
            const days = (currentDate, lastPWDDate) => {
                let difference = currentDate.getTime() - lastPWDDate.getTime();
                let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                return TotalDays;
            }
            var diffDays = days(currentDate, lastPWDDate);

            var pwdExpiry = new Date(passwordExpiryNotificationDate);
            var pwdExpiry_d = pwdExpiry.getDate();
            var pwdExpiry_m = pwdExpiry.getMonth();
            var pwdExpiry_y = pwdExpiry.getFullYear();
            var pwdExpiryNotification = pwdExpiry_y + "-" + (pwdExpiry_m + 1) + "-" + pwdExpiry_d;

            var currentDate_d = currentDate.getDate();
            var currentDate_m = currentDate.getMonth();
            var currentDate_y = currentDate.getFullYear();
            var newDate = currentDate_y + "-" + (currentDate_m + 1) + "-" + currentDate_d;

            if (diffDays > 83 && diffDays <= 90) {
                $rootScope.diffDays = (91 - diffDays);
                //if (!isnotified ) {
                if (pwdExpiryNotification !== newDate && !isnotified) {
                    var model = {};
                    model.expiryDays = $rootScope.diffDays;
                    localStorage.setItem("isNotified", true);
                    localStorage.setItem("lastNotifieddate", new Date());
                    userResource.passWordExpiryNotification({ model }, function (res) {
                        localStorage.setItem("isNotified", true);
                        localStorage.setItem("lastNotifieddate", new Date());
                        localStorage.setItem("diffDays", (91 - diffDays));
                        localStorage.setItem("showNotification", true);
                        $rootScope.showNotification = true;
                    });

                }
                else if (pwdExpiryNotification === newDate) {
                    localStorage.setItem("isNotified", true);
                    localStorage.setItem("lastNotifieddate", new Date(passwordExpiryNotificationDate));
                    localStorage.setItem("diffDays", (91 - diffDays));
                    localStorage.setItem("showNotification", true);
                    $rootScope.showNotification = true;
                }
                // commented since not in use
                //else {// 6hrs once it will notify to an user
                //    var diffMs = (new Date() - lastNotifieddate); // milliseconds between two dates
                //    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours

                //    if (diffHrs >= notifyperiod) {
                //        //trigger notification
                //        var model = {};
                //        model.expiryDays = $rootScope.diffDays;
                //        userResource.passWordExpiryNotification({ model }, function (res) {
                //            localStorage.setItem("lastNotifieddate", new Date());
                //            $rootScope.diffDays = (91 - diffDays);
                //            localStorage.setItem("showNotification", true);
                //            $rootScope.showNotification = true;
                //        });
                //    }
                //}
            }
            else if (diffDays > 90) {
                //toaster.info("Your password has been expired.");
                //$rootScope.showNotification = true;
                //localStorage.setItem("showNotification", true);
                //localStorage.setItem("menu", null);
                //$rootScope.menu = [];
                //localStorage.menu = [];
                //$location.path('/changePassword');
                if ($rootScope.toState.name == "changePassWordEmail") {
                    $location.path('/changePassWordEmail');
                }
                else {
                    $location.path('/login');
                }

            }
            else {
                localStorage.setItem("showNotification", false);
            }
            var notifyValue = localStorage.getItem("showNotification");
            $rootScope.showNotification = (notifyValue != undefined && notifyValue == 'true') ? true : false;
        }
        //#endregion

        $state.previous = fromState;
        $state.previousParams = fromParams;
        var http = $injector.get('$http');
        var appReqs = http.pendingRequests.filter(x => x.noLoader == undefined && x.url.includes('api'));
        if (appReqs.length == 0) {
            $rootScope.$broadcast('globalLoadingEnd');


        }
        isStateInProcess = false;
        /*added by shwetha,To prevent black screen while navigating state*/
        $('.modal').modal('hide');
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
        /*end*/

    });


    //$rootScope.$evalAsync(function () {

    //    var http = $injector.get('$http');
    //    console.log(http.pendingRequests.length)
    //    $timeout(function () {
    //        const elements = document.getElementsByClassName("fa fa-pencil");
    //        console.log('remove22' + JSON.stringify(elements));
    //        while (elements.length > 0) {
    //            elements[0].remove();
    //            console.log('remove')

    //        };}, 0);

    //});





    //end
}]);

//angular.element(document).ready(function () {

//    const elements = document.getElementsByClassName("fa fa -pencil");
//    console.log('remove22' + JSON.stringify(elements));
//    while (elements.length > 0) {
//        elements[0].remove();
//        console.log('remove')

//    };
//});



//myApp.run(function ($rootScope, $templateCache) {
//    $rootScope.$on('$viewContentLoaded', function () {
//        $templateCache.removeAll();
//    });
//});

myApp.config(function ($httpProvider, $compileProvider) {

    $httpProvider.interceptors.push('authInterceptorService');
    $httpProvider.interceptors.push('httpRequestInterceptor');
    $compileProvider.debugInfoEnabled(false);//added by shwetha 
});



myApp.run(['authService', function (authService) {
    authService.fillAuthData();
}]);

myApp.filter('unique', function () {
    return function (arr, field) {
        return _.uniq(arr, function (a) { return a[field]; });
    };
});

//|| (toState.name == "profile"))

//range
myApp.filter('range', function () {
    return function (input, total) {
        total = parseInt(total);
        for (var i = 0; i < total; i++)
            input.push(i);
        return input;
    };
});

myApp.directive('toggle', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            if (attrs.toggle == "tooltip") {
                $(element).tooltip();
                element.bind('click', function () {
                    $(element).tooltip('hide');
                });
            }
            if (attrs.toggle == "popover") {
                $(element).popover();
            }
        }
    };
});

myApp.directive("limitToMax", function () {
    return {
        link: function (scope, element, attributes) {
            element.on("keydown keyup", function (e) {
                if (Number(element.val()) > Number(attributes.max) &&
                    e.keyCode != 46 // delete
                    &&
                    e.keyCode != 8 // backspace
                ) {
                    e.preventDefault();
                    element.val(null);
                }
            });
        }
    };
});

myApp.directive("checkingInput", function () {
    return {
        link: function (scope, element, attributes) {
            //element.on("keydown keyup", function (e) {
            //console.log(" checkingInput " + element.val())
            //if (Number(element.val()) > Number(attributes.max) &&
            //    e.keyCode != 46 // delete
            //    &&
            //    e.keyCode != 8 // backspace
            //) {
            //    e.preventDefault();
            //    element.val(null);
            //}
            //});
        }
    };
});

/*
 Added by shwetha
 Desc:To limit decimal places to 2, for amount fields.
 */
myApp.directive('limitDec', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            var input = function (inputValue) {
                if (inputValue != undefined) {
                    if (inputValue === undefined) { inputValue = 0; }
                    if (inputValue.toString().indexOf(".") > -1 && (inputValue.toString().split('.')[1].length > 2)) {
                        var inputdata = inputValue.toString();
                        inputValue = parseFloat(inputdata.substring(0, inputdata.length - 1));
                        modelCtrl.$setViewValue(inputValue);
                        modelCtrl.$render();
                    }
                    return inputValue;
                }
            }
            modelCtrl.$parsers.push(input);
        }
    };
});

myApp.directive("fileread", [function () {
    return {
        scope: {
            opts: '='
        },
        link: function ($scope, $elm, $attrs) {
            $elm.on('change', function (changeEvent) {
                var reader = new FileReader();
                //$scope.loading ="../assets/img/loader/loader.gif"
                //$("#loader").show();
                // debugger;
                $scope.loader = true;
                reader.onload = function (evt) {
                    $scope.$apply(function () {
                        var data = evt.target.result;
                        $scope.loader = true;
                        var workbook = XLSX.read(data, { type: 'binary' });

                        var headerNames = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
                        var data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                        //console.log('headerNames' + JSON.stringify(headerNames));

                        // console.log('data' + JSON.stringify(data))
                        _.each(data, function (existingLineItem) {
                            existingLineItem.dFSCostId = existingLineItem.DFSCostId;
                            existingLineItem.setNumber = existingLineItem.set_no;
                            existingLineItem.description = existingLineItem.Description;
                            existingLineItem.setNote = existingLineItem.subject_note;
                            existingLineItem.productCode = existingLineItem.Prod_code;
                            existingLineItem.lenseCount = existingLineItem.lens_count;
                            existingLineItem.remarks = existingLineItem.Remarks;

                            existingLineItem.lDesn = existingLineItem.L_Desn;
                            existingLineItem.luMAT = existingLineItem.L_uMAT;
                            existingLineItem.lbcd = existingLineItem.L_bcd;
                            existingLineItem.ldia = existingLineItem.L_dia;
                            existingLineItem.Lbcmm1 = existingLineItem.L_bcmm1;
                            existingLineItem.lclr = existingLineItem.L_clr;
                            existingLineItem.lsph = existingLineItem.L_sph;
                            existingLineItem.lcyl = existingLineItem.L_cyl;
                            existingLineItem.lax = existingLineItem.L_ax;
                            existingLineItem.ladd = existingLineItem.L_add;
                            existingLineItem.let = existingLineItem.L_et;
                            existingLineItem.lct = existingLineItem.L_ct;
                            existingLineItem.lbcmm2 = existingLineItem.L_bcmm2;
                            existingLineItem.lpoz = existingLineItem.L_poz;
                            existingLineItem.lpc1 = existingLineItem.L_pc1;
                            existingLineItem.lpE1 = existingLineItem.L_pE1;
                            existingLineItem.lw1 = existingLineItem.L_w1;
                            existingLineItem.lpc2 = existingLineItem.L_pc2;
                            existingLineItem.lpE2 = existingLineItem.L_pE2;
                            existingLineItem.lw2 = existingLineItem.L_w2;
                            existingLineItem.lpc3 = existingLineItem.L_pc3;
                            existingLineItem.lpE3 = existingLineItem.L_pE3;
                            existingLineItem.lw3 = existingLineItem.L_w3;
                            existingLineItem.lpc4 = existingLineItem.L_pc4;
                            existingLineItem.lpE4 = existingLineItem.L_pE4;
                            existingLineItem.lw4 = existingLineItem.L_w4;
                            existingLineItem.lpc5 = existingLineItem.L_pc5;
                            existingLineItem.lpE5 = existingLineItem.L_pE5;
                            existingLineItem.lw5 = existingLineItem.L_w5;
                            existingLineItem.lFC1 = existingLineItem.L_FC1;
                            existingLineItem.lFe1 = existingLineItem.L_Fe1;
                            existingLineItem.laoz = existingLineItem.L_aoz;
                            existingLineItem.lacm = existingLineItem.L_acm;
                            existingLineItem.lFC3 = existingLineItem.L_FC3;
                            existingLineItem.lFe3 = existingLineItem.L_Fe3;
                            existingLineItem.lFD3 = existingLineItem.L_FD3;
                            existingLineItem.lFC4 = existingLineItem.L_FC4;
                            existingLineItem.lFe4 = existingLineItem.L_Fe4;
                            existingLineItem.lFD4 = existingLineItem.L_FD4;
                            existingLineItem.lrem = existingLineItem.L_rem;
                            existingLineItem.lBtor = existingLineItem.L_Btor;
                            existingLineItem.lSag = existingLineItem.L_Sag;
                            existingLineItem.ltoric = existingLineItem.L_toric;
                            existingLineItem.lLabel = existingLineItem.L_Label;

                            existingLineItem.rDesn = existingLineItem.R_Desn;
                            existingLineItem.ruMAT = existingLineItem.R_uMAT;
                            existingLineItem.rbcd = existingLineItem.R_bcd;
                            existingLineItem.rdia = existingLineItem.R_dia;
                            existingLineItem.rbcmm1 = existingLineItem.R_bcmm1;
                            existingLineItem.rclr = existingLineItem.R_clr;
                            existingLineItem.rsph = existingLineItem.R_sph;
                            existingLineItem.rcyl = existingLineItem.R_cyl;
                            existingLineItem.rax = existingLineItem.R_ax;
                            existingLineItem.radd = existingLineItem.R_add;
                            existingLineItem.ret = existingLineItem.R_et;
                            existingLineItem.rct = existingLineItem.R_ct;
                            existingLineItem.rbcmm2 = existingLineItem.R_bcmm2;
                            existingLineItem.rpoz = existingLineItem.R_poz;
                            existingLineItem.rpc1 = existingLineItem.R_pc1;
                            existingLineItem.rpE1 = existingLineItem.R_pE1;
                            existingLineItem.rw1 = existingLineItem.R_w1;
                            existingLineItem.rpc2 = existingLineItem.R_pc2;
                            existingLineItem.rpE2 = existingLineItem.R_pE2;
                            existingLineItem.rw2 = existingLineItem.R_w2;
                            existingLineItem.rpc3 = existingLineItem.R_pc3;
                            existingLineItem.rpE3 = existingLineItem.R_pE3;
                            existingLineItem.rw3 = existingLineItem.R_w3;
                            existingLineItem.rpc4 = existingLineItem.R_pc4;
                            existingLineItem.rpE4 = existingLineItem.R_pE4;
                            existingLineItem.rw4 = existingLineItem.R_w4;
                            existingLineItem.rpc5 = existingLineItem.R_pc5;
                            existingLineItem.rpE5 = existingLineItem.R_pE5;
                            existingLineItem.rw5 = existingLineItem.R_w5;
                            existingLineItem.rFC1 = existingLineItem.R_FC1;
                            existingLineItem.rFe1 = existingLineItem.R_Fe1;
                            existingLineItem.raoz = existingLineItem.R_aoz;
                            existingLineItem.racm = existingLineItem.R_acm;
                            existingLineItem.rFC3 = existingLineItem.R_FC3;
                            existingLineItem.rFe3 = existingLineItem.R_Fe3;
                            existingLineItem.rFD3 = existingLineItem.R_FD3;
                            existingLineItem.rFC4 = existingLineItem.R_FC4;
                            existingLineItem.rFe4 = existingLineItem.R_Fe4;
                            existingLineItem.rFD4 = existingLineItem.R_FD4;
                            existingLineItem.rrem = existingLineItem.R_rem;
                            existingLineItem.rBtor = existingLineItem.R_Btor;
                            existingLineItem.rSag = existingLineItem.R_Sag;
                            existingLineItem.rtoric = existingLineItem.R_toric;
                            existingLineItem.rLabel = existingLineItem.R_Label;
                            existingLineItem.baseCost = existingLineItem.R_base_cost;
                            existingLineItem.rLensNo = (existingLineItem.R_Desn != "" && existingLineItem.R_Desn != null) ? existingLineItem.R_LensNo : null;
                            existingLineItem.lLensNo = (existingLineItem.L_Desn != "" && existingLineItem.L_Desn != null) ? existingLineItem.L_LensNo : null;

                            existingLineItem.rstyle = existingLineItem.R_Style;
                            existingLineItem.lstyle = existingLineItem.L_Style;
                            existingLineItem.rlabelCode = existingLineItem.R_Label_Code;
                            existingLineItem.llabelCode = existingLineItem.L_Label_Code;
                            existingLineItem.rQuad = existingLineItem.R_Quad;
                            existingLineItem.lQuad = existingLineItem.L_Quad;
                            existingLineItem.rDotNo = existingLineItem.R_Dot;
                            existingLineItem.lDotNo = existingLineItem.L_Dot;
                            existingLineItem.rDotColor = existingLineItem.R_Dot_Color;
                            existingLineItem.lDotColor = existingLineItem.L_Dot_Color;

                        })

                        $scope.opts.columnDefs = [];
                        $scope.loader = true;
                        headerNames.forEach(function (h) {
                            $scope.opts.columnDefs.push({ field: h });
                            // console.log('$scope.opts' + JSON.stringify($scope.opts));

                        });
                        $scope.records = data.map(function (item) {
                            //   $scope.loader = true;
                            //    delete (item['MotherName']); delete (item['MotherQualification']); delete (item['MotherOccupation']); delete (item['MotherDesignation']);
                            //    delete (item['MotherOrganisation/Company']); delete (item['MotherOfficeAddress']); delete (item['MotherResidentialAddress']);
                            //    delete (item['MotherContactNumber']); delete (item['MotherE-Mail']); delete (item['MotherAnnualIncome']);

                            //    delete (item['FatherName']); delete (item['FatherQualification']); delete (item['FatherOccupation']); delete (item['FatherDesignation']);
                            //    delete (item['FatherCompanyOrganisation/Company']); delete (item['FatherOfficeAddress']); delete (item['FatherResidentialAddress']);
                            //    delete (item['FatherContactNumber']); delete (item['FatherE-Mail']); delete (item['FatherAnnualIncome']);
                            return item;
                        });

                        $scope.opts.data = $scope.records;
                        $scope.loader = true;
                        //console.log("records" + JSON.stringify($scope.records))
                        // console.log('$scope.opts.data' + JSON.stringify($scope.opts.data));
                        $elm.val(null);
                    });
                };

                reader.readAsBinaryString(changeEvent.target.files[0]);
            });
        }
    }
}]);

myApp.directive('capitalizeFirst', function ($parse) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            var capitalize = function (inputValue) {
                if (inputValue != undefined) {
                    if (inputValue === undefined) { inputValue = ''; }
                    //var capitalized = inputValue.charAt(0).toUpperCase() + inputValue.substring(1);
                    var capitalized = inputValue.toLowerCase().replace(/([^- '])([^- ']*)/gi, function (v, v1, v2) { return v1.toUpperCase() + v2; });
                    if (capitalized !== inputValue) {
                        modelCtrl.$setViewValue(capitalized);
                        modelCtrl.$render();
                    }
                    return capitalized;
                }

            }
            modelCtrl.$parsers.push(capitalize);
            capitalize($parse(attrs.ngModel)(scope)); // capitalize initial value
        }
    };
});


myApp.directive('numbersOnly', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9-.]/g, '');

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
});

myApp.directive('capitalizeEach', function ($parse) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            var capitalize = function (inputValue) {
                if (inputValue != undefined) {
                    if (inputValue === undefined) { inputValue = ''; }
                    //var capitalized = inputValue.toLowerCase().replace(/^(.)|\s(.)/g, function (v) { return v.toUpperCase(); });
                    var capitalized = inputValue.toLowerCase().replace(/([^- '])([^- ']*)/gi, function (v, v1, v2) { return v1.toUpperCase() + v2; });
                    if (capitalized !== inputValue) {
                        modelCtrl.$setViewValue(capitalized);
                        modelCtrl.$render();
                    }
                    return capitalized;
                }

            }
            modelCtrl.$parsers.push(capitalize);
            capitalize($parse(attrs.ngModel)(scope)); // capitalize initial value. modified by shwetha.
        }
    };
});


myApp.directive('lowercase', function ($parse) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            var lowerCase = function (inputValue) {
                if (inputValue != undefined && (typeof inputValue === 'string' || inputValue instanceof String)) {
                    if (inputValue === undefined) { inputValue = ''; }
                    var smallerCase = inputValue.toLowerCase()
                    if (smallerCase !== inputValue) {
                        modelCtrl.$setViewValue(smallerCase);
                        modelCtrl.$render();
                    }
                    return smallerCase;
                }

            }
            modelCtrl.$parsers.push(lowerCase);
            lowerCase($parse(attrs.ngModel)(scope));
        }
    };
});

//added by shwetha.for 1 decimal place.
myApp.filter('oneDecimalPlace', function () {
    return function (x) {
        // debugger;
        if (x !== null && x !== "" && x !== undefined) {
            var input = x.toString();
            if (input.includes(".")) {
                // var inputValue = input.toString().split(".")[1];
                if (input.toString().split(".")[1].length >= 1) {
                    var out = parseFloat(input).toFixed(1);
                    return out;
                }
            }
            else {
                var out = parseFloat(input).toFixed(1);
                return out;
            }
        }
        else {
            return x;
        }


    };
});

//added by shwetha.for 2 decimal place.
myApp.filter('twoDecimalPlace', function () {
    return function (x) {
        // debugger;
        if (x || x == 0) {
            var input = x.toString();
            if (input.includes(".")) {
                // var inputValue = input.toString().split(".")[1];
                if (input.toString().split(".")[1].length >= 1) {
                    var out = parseFloat(input).toFixed(2);
                    return out;
                }
            }
            else {
                var out = parseFloat(input).toFixed(2);
                return out;
            }
        }
        else {
            return x;
        }


    };
});
//added by shwetha.for 3 decimal place.
myApp.filter('threeDecimalPlace', function () {
    return function (x) {
        //debugger;
        // console.log(x);
        if (x !== null && x !== "" && x !== undefined) {
            var input = x.toString();
            if (input.includes(".")) {
                // var inputValue = input.toString().split(".")[1];
                if (input.toString().split(".")[1].length >= 1) {
                    var out = parseFloat(input).toFixed(3);
                    return out;
                }
            }
            else {
                var out = parseFloat(input).toFixed(3);
                return out;
            }
        }
        else {
            return x;
        }


    };
});
//added by shwetha.for 4 decimal place.
myApp.filter('fourDecimalPlace', function () {
    return function (x) {
        //debugger;
        if (x !== null && x !== "" && x !== undefined) {
            var input = x.toString();
            if (input.includes(".")) {
                // var inputValue = input.toString().split(".")[1];
                if (input.toString().split(".")[1].length >= 1) {
                    var out = parseFloat(input).toFixed(4);
                    return out;
                }
            }
            else {
                var out = parseFloat(input).toFixed(4);
                return out;
            }
        }
        else {
            return x;
        }

    };
});


//added by shwetha.if value is positive then concat "+" sign with that value.
myApp.filter('appendPositiveSign', function () {
    return function (x) {
        //debugger;
        var inputValue = "";
        if (x > 0) {
            inputValue = "+" + x;
            return inputValue;
        }
        else {
            return x;
        }

    };
});

myApp.filter('positive', function () {
    return function (input) {
        if (!input) {
            return 0;
        }

        return Math.abs(input);
    };
})

//added by shwetha. for decimal places and fraction number for input as well as calculated values.----starts here-->>>>>>>>--------//
myApp.directive('numericInput', function ($filter, $browser, $locale) {
    // debugger;
    return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
            if (!$element) {
                return;
            }

            var replaceRegex = new RegExp($locale.NUMBER_FORMATS.GROUP_SEP, 'g');
            var fraction = $attrs.fraction || $locale.NUMBER_FORMATS.PATTERNS[0].maxFrac;
            var listener = function () {
                var value = $element.val().replace(replaceRegex, '')
                $element.val($filter('number')(value, fraction))
            }

            //---------- This runs when we update the text field----------------// no need of this bcz we are using number field.its required if type is text field.
            //ngModelCtrl.$parsers.push(function (viewValue) {
            //    var newVal = viewValue.replace(replaceRegex, '');
            //    var newValAsNumber = newVal * 1;

            //    // check if new value is numeric, and set control validity
            //    if (isNaN(newValAsNumber)) {
            //        debugger;
            //        ngModelCtrl.$setValidity(ngModelCtrl.$name + 'Numeric', false);
            //    }
            //    else {
            //        debugger;
            //        newVal = newValAsNumber.toFixed(fraction);
            //        ngModelCtrl.$setValidity(ngModelCtrl.$name + 'Numeric', true);
            //    }
            //    return newVal;

            //})

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function () {
                $element.val($filter('number')(ngModelCtrl.$viewValue, fraction))
            }

            $element.bind('change', listener);
            $element.bind('keydown', function (event) {
                var key = event.keyCode
                // If the keys include the CTRL, SHIFT, ALT, or META keys, home, end, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key == 91 || (15 < key && key < 19) || (35 <= key && key <= 40))
                    return
                //$browser.defer(listener) // Have to do this or changes don't get picked up properly
            })
        }
        //$element.bind('paste cut', function() {
        //                $browser.defer(listener)  
        //            })


    }
});

myApp.directive('fileOnChange', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var onChangeHandler = scope.$eval(attrs.fileOnChange);
            element.on('change', onChangeHandler);
            element.on('$destroy', function () {
                element.off();
            });
        }
    };
});

//**********************UI Grid Scrolling Issue Fix***************************
myApp.config(function ($provide) {
    $provide.decorator('Grid', function ($delegate, $timeout) {
        $delegate.prototype.renderingComplete = function () {
            if (angular.isFunction(this.options.onRegisterApi)) {
                this.options.onRegisterApi(this.api);
            }
            this.api.core.raise.renderingComplete(this.api);
            $timeout(function () {
                var $viewport = $('.ui-grid-render-container');
                ['touchstart', 'touchmove', 'touchend', 'keydown', 'wheel', 'mousewheel', 'DomMouseScroll', 'MozMousePixelScroll'].forEach(function (eventName) {
                    $viewport.unbind(eventName);
                });
            }.bind(this));
        };
        return $delegate;
    });

});
//--------------------------------------end here-->>>>>>>>>>>>>-------------------------------------//
window.onbeforeunload = function (event) {
    if (event.target.location.hash.contains("flag=1")) {

    } else {
        var message = 'Sure you want to leave?';
        if (typeof event == 'undefined') {
            event = window.event;
        }
        if (event) {
            event.returnValue = message;
        }
        return message;
    }
}



myApp.filter('customCurrency', ["$filter", function ($filter) {
    return function (amount, currencySymbol) {
        var currency = $filter('currency');

        if (amount < 0) {
            return currency(amount, currencySymbol).replace("(", "-").replace(")", "");
        }

        return currency(amount, currencySymbol);
    };

}])

myApp.directive('hideZero', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$formatters.push(function (inputValue) {
                if (inputValue == 0) {
                    return '';
                }
                return inputValue;
            });
            ngModel.$parsers.push(function (inputValue) {
                if (inputValue == 0) {
                    ngModel.$setViewValue('');
                    ngModel.$render();
                }
                return inputValue;
            });
        }
    };
});
//===================added by shwetha for enter key should work like tab means move to next field when user click on enter=============>>>>>>>>>>>>>>>>>>>>>>>
myApp.directive('enter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                event.preventDefault();
                var fields = $(this).parents('form:eq(0),body').find('input, textarea, password, select, button').filter(':visible:not([readonly]):enabled');
                var index = fields.index(this);
                if (index > -1 && (index + 1) < fields.length) {
                    var no = recursion(fields, index, 1);
                    fields.eq(index + no).focus();
                    if (fields.eq(index + no).is('button')) {
                        fields.eq(index + no).click();
                    } else {
                        fields.eq(index + no).select();
                    }
                }
            }

            function recursion(fields, index, i) {
                let res = i;
                try {
                    if (fields[index + i].attributes.noenter != undefined) {
                        return recursion(fields, index, i + 1);
                    } else {
                        return res;
                    }
                } catch (e) {
                    return res;
                }
            }
        });
    };
});
//============================================ end =====================================================================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//myApp.directive('myTable', function () {
//    return {
//        restrict: 'E',
//        templateUrl: 'app/views/common/table.html?v=' + ENV.version,
//        scope: {
//            items: '='
//        },
//        link: function (scope) {
//            scope.keys = [];
//            for (var key in scope.items[0]) {
//                scope.keys.push(key);
//            }
//        }
//    };
//});
//myApp.directive('inputDropdown', function ($compile) {

//    var template =
//'<input ng-model="ngModel">' +
//'<div class="dropdown">' +
//    '<div ng-repeat="value in list | filter: ngModel">' +
//        '<div ng-mousedown="select($event, value)">{{value}}</div>' +
//    '</div>' +
//'</div>';

//    return {
//        restrict: 'EA',
//        scope: {
//            ngModel: '=',
//            list: '=',
//            onSelect: '&',
//            onBlur:'&',
//        },
//        template: template,
//        link: function (scope, element, attrs) {
//            element.addClass('input-dropdown');
//            scope.select = function (e, value) {
//                scope.ngModel = value;
//                scope.onSelect({ $event: e, value: value });
//                scope.onBlur({ $event: e, value: value });
//            };
//        }
//    };
//});

myApp.filter('filterWithOr', function ($filter) {
    var comparator = function (actual, expected) {
        if (angular.isUndefined(actual)) {
            // No substring matching against `undefined`
            return false;
        }
        if ((actual === null) || (expected === null)) {
            // No substring matching against `null`; only match against `null`
            return actual === expected;
        }
        if ((angular.isObject(expected) && !angular.isArray(expected)) || (angular.isObject(actual) && !hasCustomToString(actual))) {
            // Should not compare primitives against objects, unless they have custom `toString` method
            return false;
        }


        actual = angular.lowercase('' + actual);
        if (angular.isArray(expected)) {
            var match = false;
            expected.forEach(function (e) {
                e = angular.lowercase('' + e);
                if (actual.indexOf(e) !== -1) {
                    match = true;
                }
            });
            return match;
        } else {
            expected = angular.lowercase('' + expected);
            return actual.indexOf(expected) !== -1;
        }
    };
    return function (campaigns, filters) {
        return $filter('filter')(campaigns, filters, comparator);
    };
});
//added by shwetha to check on form data change------------//
myApp.directive("formOnChange", function ($parse) {
    return {
        require: "form",
        link: function (scope, element, attrs) {
            var cb = $parse(attrs.formOnChange);
            element.on("change", function () {
                cb(scope);
            });
        }
    }
});

myApp.directive('autoFocus', function () {
    return {
        restrict: 'A',
        link: function (scope, element) {
            element[0].focus();
        }
    }
});




myApp.filter('currentdate', ['$filter', function ($filter) {
    return function () {
        return $filter('date')(new Date(), 'MM/dd/yyyy');
    };
}])


myApp.filter('formatZero', function () {
    return function (input) {
        if (input == 0 || input === '0') {
            return '0.00';
        }
        return input;
    };
});
myApp.directive('formatZero', function ($filter) {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            // Formatter to display 0.00
            ngModel.$formatters.push(function (value) {
                return value === 0 ? '0.00' : value;
            });

            // Parser to convert '0.00' back to 0
            ngModel.$parsers.push(function (value) {
                return parseFloat(value) || 0;
            });

            element.on('blur', function () {
                if (ngModel.$modelValue === 0) {
                    element.val('0.00');
                }
            });

            element.on('focus', function () {
                if (element.val() === '0.00') {
                    element.val('0');
                }
            });
        }
    };
});
myApp.factory('httpRequestInterceptor', function ($q, $injector, $rootScope, toaster, $location, $localStorage) {
    return {
        'request': function (config) {
            if (config.url.includes("api")) {

                /*Please do not change Headers property name, It may end up of breaking the application*/
                config.headers['processedZone'] = (new Date()).getTimezoneOffset();
                config.headers['ConnectionId'] = $.connection.notificationHub.connection.id;

                if (config.noLoader == true) {
                    return config;
                }
                var http = $injector.get('$http');
                var appReqs = http.pendingRequests.filter(x => x.noLoader == undefined && x.url.includes('api'));
                if (appReqs.length === 0) {
                    $rootScope.$broadcast('globalLoadingStart');
                }
            }
            return config;
        },
        'response': function (response) {
            if (response.config.url.includes("api")) {
                if (response.config.noLoader == true) {
                    return response;
                }
                var http = $injector.get('$http');
                var appReqs = http.pendingRequests.filter(x => x.noLoader == undefined && x.url.includes('api'));
                if (appReqs.length == 0 && isStateInProcess == false) {
                    $rootScope.$broadcast('globalLoadingEnd');
                }
            }
            return response;
        },
        'responseError': function (rejection) {
            var errormsg;
            $rootScope.$broadcast('globalLoadingEnd');
            if (rejection.status != 409) {

                if (!rejection.data) {
                    errormsg = "something went wrong!";
                    return $q.reject(rejection);
                } else {
                    errormsg = rejection.data.message ? rejection.data.message : rejection.data.error_description;
                }
                if (rejection.status === 401) {
                    var authService = $injector.get('authService');
                    authService.logOut();
                } else {
                }

                if (rejection.status != 501 && rejection.data.message != undefined) {
                    toaster.error(errormsg);
                }
            }
            return $q.reject(rejection);
        }
    };
});

myApp.factory("signalR", function () {
    var factory = {};

    factory.url = function (url) {
        $.connection.hub.url = url;
    }

    factory.setHubName = function (hubName) {
        factory.hub = hubName;
    }

    factory.connectToHub = function () {
        return $.connection[factory.hub];
    }

    factory.client = function () {
        var hub = factory.connectToHub();
        return hub.client;
    }

    factory.server = function () {
        var hub = factory.connectToHub();
        return hub.server;
    }

    factory.start = function (fn) {
        return $.connection.hub.start().done(fn);
    }

    return factory;
});